import cuid from "cuid";
import { AccountEvent as AccountEventClient } from "./api/AccountEvent";
import { AccountEvents as AccountEventsClient } from "./api/AccountEvents";
import { Accounts as AccountsClient } from "./api/Accounts";
import { Activity as ActivityClient } from "./api/Activity";
import { Bootstrap as BootstrapClient } from "./api/Bootstrap";
import { Content as ContentClient } from "./api/Content";
import { Device as DeviceClient } from "./api/Device";
import { Echo as EchoClient } from "./api/Echo";
import { Login } from "./api/Login";
import { Logs as LogsClient } from "./api/Logs";
import { Userinfo as UserInfoClient } from "./api/Userinfo";
import { WorkspaceId as SearchClient } from "./api/WorkspaceId";
import { Workspaces as WorkspacesClient } from "./api/Workspaces";
import { Call as CallClient } from "./api/Call";
import {
  AccountEventRequest,
  AccountEventRequests,
  AppContext,
  CallStatus,
  ContentEventStep,
  ContentEventStepStatus,
  ContentLanguageRequest,
  CreateContentEventRequests,
  DeviceContext,
  DeviceRegistration,
  DrivingStatus,
  HandsFreeStatusRequest,
  HeartbeatRequest,
  LocationsRequest,
  SearchFeedsRequest,
  SearchFeedsResponse,
  SearchMessageResponse,
  SearchMessagesRequest,
  UploadDeviceLogs,
  WorkspaceInvitation,
  WorkspaceRole,
} from "./api/data-contracts";
import { HttpResponse, RequestParams } from "./api/http-client";

export default class Client {
  public apiBaseUrl: string;
  public authBaseUrl: string;

  public userInfoClient: UserInfoClient;
  public contentClient: ContentClient;
  public accountEventClient: AccountEventClient;
  public accountEventsClient: AccountEventsClient;
  public loginClient: Login;
  public accountClients: AccountsClient;
  public workspacesClient: WorkspacesClient;
  public searchClient: SearchClient;
  public bootstrapClient: BootstrapClient;
  public logsClient: LogsClient;
  public echoClient: EchoClient;
  public activityClient: ActivityClient;
  public deviceClient: DeviceClient;
  public callClient: CallClient;
  public baseDomain: string;
  public login?: () => void;
  public logout?: (returnTo?: string) => void;
  public logoutLink?: (returnTo?: string) => string;
  public headers?: any;
  public eventMap: any;

  public appContext: AppContext;
  public deviceContext: DeviceContext;

  constructor({
    baseDomain,
    headers,
    login,
    logout,
    logoutLink,
  }: {
    baseDomain?: string;
    headers?: any;
    login?: () => void;
    logout?: (returnTo?: string) => void;
    logoutLink?: (returnTo?: string) => string;
  }) {
    this.appContext = {};
    this.deviceContext = {};
    this.eventMap = new Map();
    this.headers = headers;
    this.baseDomain = baseDomain || "https://dev.app.storyboardtesting.com";

    this.login = login;
    this.logout = logout;
    this.logoutLink = logoutLink;

    this.apiBaseUrl = `${this.baseDomain}/v1`;
    this.authBaseUrl = `${this.baseDomain}/auth`;

    this.userInfoClient = new UserInfoClient({
      baseUrl: this.authBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.contentClient = new ContentClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.accountEventClient = new AccountEventClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.accountEventsClient = new AccountEventsClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.loginClient = new Login({
      baseUrl: this.authBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.accountClients = new AccountsClient({
      baseUrl: this.authBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.workspacesClient = new WorkspacesClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.bootstrapClient = new BootstrapClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.logsClient = new LogsClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.echoClient = new EchoClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.searchClient = new SearchClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.activityClient = new ActivityClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.deviceClient = new DeviceClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.callClient = new CallClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
  }

  on(event: string, callback: (value: any) => void) {
    if (!this.eventMap.has(event)) {
      this.eventMap.set(event, []);
    }
    this.eventMap.get(event).push(callback);
  }

  remove(event: string, callback: (value: any) => void) {
    if (this.eventMap.has(event)) {
      const callbacks = this.eventMap.get(event).filter((cb) => cb !== callback);
      this.eventMap.set(event, callbacks);
    }
  }
  emit(event: string, ...data: any) {
    if (this.eventMap.has(event)) {
      this.eventMap.get(event).forEach((callback) => callback(...data));
    }
  }

  public async createScheduledBroadcast({
    workspaceId,
    feedIds,
    workspaceMembershipIds,
    workflowItemId,
    date,
    cron,
    timezone,
  }: {
    workspaceId: string;
    feedIds?: string[];
    workspaceMembershipIds?: string[];
    workflowItemId: string;
    date?: string;
    cron?: string;
    timezone?: string;
  }) {
    return this.processRequest(
      this.workspacesClient.triggerScheduleCreate(workspaceId, {
        feedIds,
        workspaceMembershipIds,
        workflowItemId,
        workspaceId,
        date,
        cron,
        timezone,
      }),
    );
  }

  public async updateScheduledBroadcast({
    workspaceId,
    scheduleId,
    feedIds,
    workspaceMembershipIds,
    date,
    cron,
    timezone,
  }: {
    workspaceId: string;
    scheduleId: string;
    feedIds?: string[];
    workspaceMembershipIds?: string[];
    date?: string;
    cron?: string;
    timezone?: string;
  }) {
    return this.processRequest(
      this.workspacesClient.triggerScheduleUpdate(workspaceId, scheduleId, {
        feedIds,
        workspaceMembershipIds,
        date,
        cron,
        timezone,
      }),
    );
  }

  public async getScheduledBroadcasts(workspaceId: string) {
    return this.processRequest(this.workspacesClient.scheduledBroadcastsDetail(workspaceId));
  }

  public async publishBroadcast({
    workspaceId,
    contentId,
    workspaceMembershipIds,
    feedIds,
    inputText,
  }: {
    workspaceId: string;
    contentId: string;
    workspaceMembershipIds?: string[];
    feedIds?: string[];
    inputText?: string;
  }) {
    return this.processRequest(
      this.workspacesClient.publishBroadcastCreate(workspaceId, {
        contentId,
        workspaceMembershipIds,
        feedIds,
        workspaceId,
        inputText,
      }),
    );
  }

  public async createWorkspaceWorkflowItem({
    workspaceId,
    contentId,
    displayName,
    workflowItemId,
    inputText,
  }: {
    workspaceId: string;
    contentId?: string;
    displayName?: string;
    workflowItemId?: string;
    inputText?: string;
  }) {
    contentId = contentId || cuid();
    workflowItemId = workflowItemId || cuid();
    return this.processRequest(
      this.workspacesClient.workflowItemsCreate(workspaceId, {
        workspaceId,
        contentId,
        displayName,
        workflowItemId,
        inputText,
      }),
    );
  }

  public async getWorkspaceWorkflowItems(workspaceId: string) {
    return this.processRequest(this.workspacesClient.workflowItemsDetail(workspaceId));
  }

  public async deleteWorkspaceWorkflowItem(workspaceId: string, workflowItemId: string) {
    return this.processRequest(this.workspacesClient.workflowItemsDelete(workspaceId, workflowItemId));
  }

  public async publishWorkspaceWorkflowItem({
    workspaceId,
    workflowItemId,
    feedIds = [],
    workspaceMembershipIds = [],
  }: {
    workspaceId: string;
    workflowItemId: string;
    feedIds?: string[];
    workspaceMembershipIds?: string[];
  }) {
    return this.processRequest(
      this.workspacesClient.workflowItemsPublishCreate(workspaceId, workflowItemId, {
        feedIds,
        workspaceMembershipIds,
      }),
    );
  }

  public async createWorkspaceInvitations(
    workspaceId: string,
    emails?: string[],
    phoneNumbers?: string[],
    sendEmail?: boolean,
    invitations?: WorkspaceInvitation[],
  ) {
    return this.processRequest(
      this.workspacesClient.directInvitationsCreate(workspaceId, {
        emails,
        phoneNumbers,
        sendEmail,
        invitations,
      }),
    );
  }

  public async getWorkspaceInvitations(workspaceId: string) {
    return this.processRequest(this.workspacesClient.invitationsDetail(workspaceId));
  }

  public async deleteWorkspaceFeedItem(workspaceId: string, feedId: string, feedItemId: string) {
    return this.processRequest(this.workspacesClient.itemsDelete(workspaceId, feedId, feedItemId));
  }

  public async publishToWorkspaceFeed({
    workspaceId,
    feedId,
    contentId,
    itemId,
    groupId,
    url,
    text,
    preferredLanguage,
    isSilent,
  }: {
    workspaceId: string;
    feedId: string;
    contentId: string;
    itemId?: string;
    groupId?: string;
    url?: string;
    text?: string;
    preferredLanguage?: string;
    isSilent?: boolean;
  }) {
    itemId = itemId || cuid();

    return this.processRequest(
      this.workspacesClient.itemsCreate(workspaceId, feedId, {
        contentId,
        itemId,
        url,
        groupId,
        text,
        preferredLanguage,
        isSilent,
      }),
    );
  }

  public async publishTTS({
    workspaceId,
    feedId,
    contentId,
    itemId,
    groupId,
    text,
    preferredLanguage,
    isSilent,
  }: {
    workspaceId: string;
    feedId: string;
    contentId: string;
    itemId?: string;
    groupId?: string;
    text?: string;
    preferredLanguage?: string;
    isSilent?: boolean;
  }) {
    itemId = itemId || cuid();

    return this.processRequest(
      this.workspacesClient.postWorkspaces(workspaceId, feedId, {
        contentId,
        itemId,
        groupId,
        text,
        preferredLanguage,
        isSilent,
        appContext: this.appContext,
        deviceContext: this.deviceContext,
      }),
    );
  }

  public async getWorkspaceFeedEvents(workspaceId: string, feedId: string, page = 0, pageSize = 10000) {
    return this.processRequest(
      this.workspacesClient.feedsEventsDetail(workspaceId, feedId, {
        page,
        pageSize,
      }),
    );
  }

  public async getWorkspaceLatestFeedEvents(workspaceId: string, feedId: string, page = 0, pageSize = 10000) {
    return this.processRequest(
      this.workspacesClient.feedsLatestEventsDetail(workspaceId, feedId, {
        page,
        pageSize,
      }),
    );
  }

  public async getWorkspaceOldestFeedEvents(workspaceId: string, feedId: string, page = 0, pageSize = 10000) {
    return this.processRequest(
      this.workspacesClient.feedsOldestEventsDetail(workspaceId, feedId, {
        page,
        pageSize,
      }),
    );
  }

  public async getWorkspaceFeedPermissions({
    workspaceId,
    feedId,
    page = 0,
    pageSize = 1000,
  }: {
    workspaceId: string;
    feedId: string;
    page?: number;
    pageSize?: number;
  }) {
    return this.processRequest(
      this.workspacesClient.feedsPermissionsDetail(workspaceId, feedId, {
        page,
        pageSize,
      }),
    );
  }

  public async getWorkspaceFeedItems(workspaceId: string, feedId: string, page = 0, pageSize = 10) {
    return this.processRequest(
      this.workspacesClient.feedsItemsDetail(workspaceId, feedId, {
        page,
        pageSize,
      }),
    );
  }

  public async bootstrapWorkspaceFeedItems(workspaceId: string, page = 0, pageSize = 10000, timestamp?: string) {
    return this.processRequest(
      this.workspacesClient.feedItemsDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async getFeedItemContent(
    workspaceId: string,
    itemIds: string[],
    preferredLanguage?: string,
    timestamp?: string,
  ) {
    let language: ContentLanguageRequest;
    switch (preferredLanguage) {
      case "none":
        language = "original";
        break;
      default:
        language = preferredLanguage as ContentLanguageRequest;
    }

    console.log("getFeedItemContent", { language });

    return this.processRequest(
      this.workspacesClient.feedItemContentCreate(workspaceId, {
        itemIds,
        language,
        timestamp,
      }),
    );
  }

  public async getFeedItemOldestEvents(
    workspaceId: string,
    itemIds: string[],
    page = 0,
    pageSize = 1000,
    timestamp?: string,
  ) {
    return this.processRequest(
      this.workspacesClient.feedItemOldestEventsCreate(
        workspaceId,
        {
          itemIds,
        },
        { page, pageSize, timestamp },
      ),
    );
  }

  public async updateWorkspaceMember(workspaceId: string, workspaceMembershipIds: string[], role: WorkspaceRole) {
    return this.processRequest(
      this.workspacesClient.workspaceMembershipsUpdatePartialUpdate(workspaceId, {
        workspaceMembershipIds,
        role,
      }),
    );
  }

  public async removeWorkspaceMember(workspaceId: string, workspaceMembershipIds: string[]) {
    return this.processRequest(
      this.workspacesClient.workspaceMembershipsRemoveDelete(workspaceId, {
        workspaceMembershipIds,
        status: "inactive",
      }),
    );
  }

  public async bootstrapWorkspaces() {
    return this.processRequest(this.bootstrapClient.workspacesList());
  }

  public async tokenLogin(token: string) {
    return this.loginClient.tokenList({ token });
  }

  public async getUserInfo() {
    return this.processRequest(this.userInfoClient.userinfoList());
  }

  public async createContentEvent({
    contentId,
    workspaceId,
    step,
    status,
    context,
    feedId,
    error,
  }: {
    contentId: string;
    step: ContentEventStep;
    status: ContentEventStepStatus;
    workspaceId?: string;
    context?: string;
    feedId?: string;
    error?: Error;
  }) {
    return this.processRequest(
      this.contentClient.eventsCreate(contentId, {
        workspaceId,
        contentId,
        step,
        status,
        feedId,
        eventContext: context,
        timestamp: new Date().toISOString(),
        appContext: this.appContext,
        deviceContext: this.deviceContext,
        error,
      }),
    );
  }

  public async refreshContent(uploadId?: string) {
    // uploadId = uploadId || cuid();
    // this.processRequest(this.contentClient.refreshCreate({ uploadId }));
    // return uploadId;
  }

  public async createAccountEvent(body: AccountEventRequest) {
    body.id = body.id || cuid();
    body.appContext = this.appContext;
    body.deviceContext = this.deviceContext;
    // console.log('account event', body);
    return this.processRequest(this.accountEventClient.accountEventCreate(body));
  }

  public async updateAccount(accountId: string, { fullName, email }: { fullName: string; email?: string }) {
    return this.processRequest(
      this.accountClients.accountsPartialUpdate(accountId, {
        name: fullName,
        email,
      }),
    );
  }

  public async setPushNotificationToken({
    accountId,
    deviceToken,
    friendlyName,
  }: { accountId: string; deviceToken: string; friendlyName: string }) {
    return await this.processRequest(
      this.accountClients.pushnotificationdevicesUpdate(accountId, {
        enabled: true,
        surface: "web",
        deviceToken,
        friendlyName,
      }),
    );
  }

  public async deleteAccount(accountId: string) {
    await this.processRequest(this.accountClients.accountsDelete(accountId));
    return this.logout();
  }

  public async processRequest<I, T>(request: Promise<HttpResponse<I, T>>) {
    try {
      const response = await request;
      this.emit("success", true);
      if (response.status === 401) {
        console.log("401 - redirecting to login", response);
        if (this.login) this.login();
      }
      return response.data;
    } catch (e) {
      this.emit("errorCodes", e);
      console.error("ERROR", e);
      switch (e.status) {
        case 401:
          console.log("401 from error - redirecting to login");
          if (this.login) this.login();
          break;
        case 418:
          return Promise.reject(e);
        case 500:
          throw new Error("Internal Server Error");
      }
    }
  }

  public async getWorkspaceTemplates({ workspaceId }: { workspaceId: string }) {
    return this.processRequest(this.workspacesClient.templatesDetail(workspaceId));
  }

  public async createWorkspaceTemplate({
    workspaceId,
    name,
    template,
  }: {
    workspaceId: string;
    name: string;
    template: string;
  }) {
    return this.processRequest(this.workspacesClient.templatesCreate(workspaceId, { name, template }));
  }

  public async updateWorkspaceTemplate({
    workspaceId,
    templateId,
    name,
    template,
  }: {
    workspaceId: string;
    templateId: string;
    name: string;
    template: string;
  }) {
    return this.processRequest(
      this.workspacesClient.templatesPartialUpdate(workspaceId, templateId, {
        name,
        template,
      }),
    );
  }

  public async deleteWorkspaceTemplate({
    workspaceId,
    templateId,
  }: {
    workspaceId: string;
    templateId: string;
  }) {
    return this.processRequest(this.workspacesClient.templatesDelete(workspaceId, templateId));
  }

  public async uploadLogs({ logs, userMessage, suppressSlackNotification }: UploadDeviceLogs) {
    return this.processRequest(
      this.logsClient.logsCreate({
        deviceContext: this.deviceContext,
        appContext: this.appContext,
        logs,
        userMessage,
        suppressSlackNotification,
      }),
    );
  }

  public createContentEventBatch(contentId: string, request: CreateContentEventRequests) {
    return this.processRequest(this.contentClient.eventsBatchCreate(contentId, request));
  }

  public accountEventBatch(request: AccountEventRequests) {
    for (const event of request.data) {
      event.appContext = this.appContext;
      event.deviceContext = this.deviceContext;
    }
    return this.processRequest(this.accountEventsClient.accountEventsCreate(request));
  }

  public heartbeat(workspaceId: string, heartbeat: HeartbeatRequest) {
    heartbeat.appContext = this.appContext;
    heartbeat.deviceContext = this.deviceContext;
    return this.processRequest(this.workspacesClient.heartbeatCreate(workspaceId, heartbeat));
  }

  public getHandsFreeStatus(workspaceId: string, request: HandsFreeStatusRequest) {
    return this.processRequest(this.workspacesClient.handsfreestatusCreate(workspaceId, request));
  }

  public getLocations(workspaceId: string, request: LocationsRequest) {
    return this.processRequest(this.workspacesClient.locationsCreate(workspaceId, request));
  }

  public asyncEcho(data: string) {
    return this.processRequest(this.echoClient.asyncCreate({ data }));
  }

  public searchMessages(
    workspaceId: string,
    body: SearchMessagesRequest,
    params?: RequestParams,
  ): Promise<SearchMessageResponse> {
    return this.processRequest(this.searchClient.messagesCreate(workspaceId, body, params));
  }

  public searchChannels(
    workspaceId: string,
    body: SearchFeedsRequest,
    params?: RequestParams,
  ): Promise<SearchFeedsResponse> {
    return this.processRequest(this.searchClient.feedsCreate(workspaceId, body, params));
  }

  public postActivity({
    workspaceId,
    workspaceMembershipId,
    drivingStatus,
    drivingStatusConfidence,
  }: {
    workspaceId: string;
    workspaceMembershipId: string;
    drivingStatus: DrivingStatus;
    drivingStatusConfidence: number;
  }) {
    return this.processRequest(
      this.activityClient.activityCreate({
        workspaceId,
        workspaceMembershipId,
        drivingStatus,
        drivingStatusConfidence,
        deviceContext: this.deviceContext,
      }),
    );
  }

  public async registerDevice({
    deviceRegistration,
  }: {
    deviceRegistration: DeviceRegistration;
  }) {
    return this.processRequest(
      this.deviceClient.registrationCreate({
        deviceRegistration,
      }),
    );
  }

  public async subscribeToFeed({
    workspaceId,
    feedId,
    workspaceMembershipId,
  }: {
    workspaceId: string;
    feedId: string;
    workspaceMembershipId: string;
  }) {
    return this.processRequest(
      this.workspacesClient.feedsMembershipPreferencesPartialUpdate(workspaceId, feedId, workspaceMembershipId, {
        pushNotificationPreference: "all",
        handsFreeNotificationPreference: "all",
      }),
    );
  }

  public async unsubscribeFromFeed({
    workspaceId,
    feedId,
    workspaceMembershipId,
  }: {
    workspaceId: string;
    feedId: string;
    workspaceMembershipId: string;
  }) {
    return this.processRequest(
      this.workspacesClient.feedsMembershipPreferencesPartialUpdate(workspaceId, feedId, workspaceMembershipId, {
        pushNotificationPreference: "none",
        handsFreeNotificationPreference: "none",
      }),
    );
  }

  public async subscribeToFeedGroup({
    workspaceId,
    feedGroupId,
    workspaceMembershipId,
  }: {
    workspaceId: string;
    feedGroupId: string;
    workspaceMembershipId: string;
  }) {
    return this.processRequest(
      this.workspacesClient.feedGroupsMembershipPreferencesPartialUpdate(
        workspaceId,
        feedGroupId,
        workspaceMembershipId,
        {
          pushNotificationPreference: "all",
          handsFreeNotificationPreference: "all",
        },
      ),
    );
  }

  public async unsubscribeFromFeedGroup({
    workspaceId,
    feedGroupId,
    workspaceMembershipId,
  }: {
    workspaceId: string;
    feedGroupId: string;
    workspaceMembershipId: string;
  }) {
    return this.processRequest(
      this.workspacesClient.feedGroupsMembershipPreferencesPartialUpdate(
        workspaceId,
        feedGroupId,
        workspaceMembershipId,
        {
          pushNotificationPreference: "none",
          handsFreeNotificationPreference: "none",
        },
      ),
    );
  }

  /////////////////////////////
  // BOOTSTRAP ENDPOINTS
  /////////////////////////////

  public async latestUnreadEventPerFeedForOrganizers({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesLatestUnreadEventPerFeedForOrganizersDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async latestUnreadEventPerFeed({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesLatestUnreadEventPerFeedDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async bootstrapWorkspaceMemberships({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesWorkspaceMembershipsDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async bootstrapWorkspaceFeedPermissions({
    workspaceId,
    page,
    pageSize,
    timestamp,
    mine,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
    mine?: boolean;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesFeedPermissionsDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
        mine,
      }),
    );
  }

  public async bootstrapWorkspaceFeedItemsPaginated({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesFeedItemsDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async bootstrapWorkspaceFeeds({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesFeedsDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async bootstrapWorkspaceMembershipAvailabilities({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesWorkspaceMembershipAvailabilitiesDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async bootstrapWorkspaceTemplates({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesTemplatesDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async bootstrapWorkspaceAccounts({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesAccountsDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async bootstrapWorkspaceCommandAliases({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesCommandAliasesDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async bootstrapWorkspaceFeedGroups({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesFeedGroupsDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async bootstrapWorkspaceFeedGroupMemberships({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesFeedGroupMembershipsDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async bootstrapWorkspaceFeedPreferences({
    workspaceId,
    page,
    pageSize,
    timestamp,
    mine,
    feedId,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
    mine?: boolean;
    feedId?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesFeedPreferencesDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
        mine,
        feedId,
      }),
    );
  }

  public async createCall({
    callId,
    fromAccountId,
    toAccountId,
    feedId,
  }: {
    callId: string;
    fromAccountId: string;
    toAccountId: string;
    feedId?: string;
  }) {
    return this.processRequest(
      this.callClient.callCreate(callId, {
        fromAccountId,
        toAccountId,
        feedIds: [feedId],
      }),
    );
  }

  public async createCallEvent(
    callId: string,
    { callStatus, durationSeconds }: { callStatus: CallStatus; durationSeconds?: number },
  ) {
    return this.processRequest(this.callClient.callEventCreate(callId, { callStatus, durationSeconds }));
  }

  public async getLocationsFromItemIds({ workspaceId, itemIds }: { workspaceId: string; itemIds: string[] }) {
    return await this.processRequest(this.workspacesClient.feedItemsLocationsCreate(workspaceId, { itemIds }));
  }
}

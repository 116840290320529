import EmptyInbox from "@/components/Icons/EmptyInbox";
import TheButton from "@/components/TheButton";
import { Box, Typography } from "@mui/material";

export default function ChannelEmptyState({
  channelType,
  hasSearch,
  changeChannelType,
  limitedMember,
  activeFilter,
  clearFilters,
}: {
  channelType: string;
  hasSearch: boolean;
  changeChannelType: (channelType: string) => void;
  limitedMember: boolean;
  activeFilter: string | null;
  clearFilters: () => void;
}) {
  if (activeFilter || hasSearch) {
    return (
      <Box className="unread-state">
        <Typography fontWeight="bold" variant="h5">
          No results matching this filter
        </Typography>
        <Box>
          <TheButton className="my-channels-button" onClick={clearFilters}>
            Clear Filter
          </TheButton>
        </Box>
      </Box>
    );
  }

  if (channelType === "my-channels") {
    return (
      <Box className="unread-state">
        <Typography fontWeight="bold" variant="h5">
          No channels found
        </Typography>
        <Box>
          You haven't joined any channels yet. <br />
          {!limitedMember && (
            <>
              Go to{" "}
              <TheButton
                className="my-channels-button"
                onClick={() => {
                  changeChannelType("all-channels");
                }}
              >
                All Channels
              </TheButton>{" "}
              to see more conversations.
            </>
          )}
        </Box>
      </Box>
    );
  }

  if (channelType === "my-unreads") {
    return (
      <Box className="unread-state">
        <Box className="unread-state-icon">
          <EmptyInbox />
        </Box>
        <Typography fontWeight="bold" variant="h5">
          You are all caught up.
        </Typography>
        <Box>New unread messages will appear here.</Box>
        {!limitedMember && (
          <Box>
            Go to{" "}
            <TheButton
              className="my-channels-button"
              onClick={() => {
                changeChannelType("all-channels");
              }}
            >
              All Channels
            </TheButton>{" "}
            to see more conversations.
          </Box>
        )}
      </Box>
    );
  }

  if (channelType === "all-channels") {
    return (
      <Box className="unread-state">
        <Typography fontWeight="bold" variant="h5">
          No channels found
        </Typography>
        <Box>You haven't joined any channels yet.</Box>
      </Box>
    );
  }

  return (
    <Box className="unread-state">
      <Typography fontWeight="bold" variant="h5">
        No channels found
      </Typography>
    </Box>
  );
}

import { db } from "@/db/db";
import { feed, workspaceCommandAlias, wsMembershipAvailability } from "@/db/schema";
import * as turf from "@turf/turf";
import { and, eq, gte, inArray, isNotNull } from "drizzle-orm";
import { FeatureCollection, Point } from "geojson";
import log from "loglevel";
import { trace } from "@opentelemetry/api";
import { WsMembershipAvailability } from "web-client";

export async function lookupWorkspaceMembershipsWithinRadius(
  latitude: number,
  longitude: number,
  radius: number,
  attributes?: Record<string, string>,
): Promise<{ id: string; title: string }[]> {
  const span = trace.getTracer("geoLookup").startSpan("lookupWorkspaceMembershipsWithinRadius");
  span.setAttributes(attributes);
  span.setAttributes({
    latitude,
    longitude,
    radius,
  });
  const startTime = performance.now();
  const center = [longitude, latitude];
  const options = { steps: 10, units: "miles" as turf.Units };
  const circle = turf.circle(center, radius, options);

  const twelveHoursAgo = new Date(Date.now() - 1000 * 60 * 60 * 12);

  const members = await db.query.wsMembershipAvailability.findMany({
    where: and(
      isNotNull(wsMembershipAvailability.mostRecentDeviceLocationLatitude),
      isNotNull(wsMembershipAvailability.mostRecentDeviceLocationLongitude),
      gte(wsMembershipAvailability.mostRecentDeviceLocationUpdatedAt, twelveHoursAgo.toISOString()),
    ),
  });

  const memberQueryEndTime = performance.now();
  const memberPoints = generateFeaturesFromMembers(members);
  const geoCalculationStartTime = performance.now();
  const membersWithinRadius = turf.pointsWithinPolygon(memberPoints, circle);
  const geoCalculationEndTime = performance.now();

  // const fakeStartTime = performance.now();
  // const fakePoints = generateFakeLatLongs(1000000, [longitude, latitude]);
  // const fakePointsWithinRadius = turf.pointsWithinPolygon(fakePoints, circle);
  // const fakeEndTime = performance.now();
  // log.debug("FAKE", {
  //   fakeTimeMs: fakeEndTime - fakeStartTime,
  //   fakePointsWithinRadius: fakePointsWithinRadius.features.length,
  //   fakePoints: fakePoints.features.length,
  // });

  const commandAliasQueryStartTime = performance.now();
  const commandAliasChannels = await db.query.workspaceCommandAlias.findMany({
    where: and(
      eq(workspaceCommandAlias.alias, "dispatch"),
      inArray(
        workspaceCommandAlias.workspaceMembershipId,
        membersWithinRadius.features.map((feature) => feature.properties?.workspaceMembershipId),
      ),
    ),
  });

  const commandAliasQueryEndTime = performance.now();
  const foundIds = commandAliasChannels?.map((channel) => channel.feedId) ?? [];

  const feeds = await db.query.feed.findMany({
    where: inArray(feed.id, foundIds),
  });

  const overallEndTime = performance.now();

  span.setAttributes({
    numberOfMembers: members.length,
    numberOfMembersWithinRadius: membersWithinRadius.features.length,
    memberQueryTimeMs: memberQueryEndTime - startTime,
    geoCalculationTimeMs: geoCalculationEndTime - geoCalculationStartTime,
    commandAliasQueryTimeMs: commandAliasQueryEndTime - commandAliasQueryStartTime,
    overallTimeMs: overallEndTime - startTime,
  });
  span.end();

  log.debug("GEO", {
    numberOfMembers: members.length,
    numberOfMembersWithinRadius: membersWithinRadius.features.length,
    memberQueryTimeMs: memberQueryEndTime - startTime,
    geoCalculationTimeMs: geoCalculationEndTime - geoCalculationStartTime,
    commandAliasQueryTimeMs: commandAliasQueryEndTime - commandAliasQueryStartTime,
    overallTimeMs: overallEndTime - startTime,
  });

  return feeds?.map((feed) => ({ id: feed.id, title: feed.title }));
}

function generateFeaturesFromMembers(
  members: WsMembershipAvailability[],
): FeatureCollection<Point, { workspaceMembershipId: string }> {
  return {
    type: "FeatureCollection",
    features: members.map((member) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [member.mostRecentDeviceLocationLongitude, member.mostRecentDeviceLocationLatitude],
      },
      properties: {
        workspaceMembershipId: member.workspaceMembershipId,
      },
    })),
  };
}

// Feel free to delete, useful for performance testing
// function generateFakeLatLongs(count: number, near: [number, number]): FeatureCollection<Point> {
//   return {
//     type: "FeatureCollection",
//     features: Array.from({ length: count }, () => ({
//       type: "Feature",
//       geometry: {
//         type: "Point",
//         coordinates: fakeLatLongNear(near, 10),
//       },
//       properties: {},
//     })),
//   };
// }

// function fakeLatLongNear(near: [number, number], radius: number): [number, number] {
//   const [longitude, latitude] = near;
//   return [longitude + (Math.random() * 2 - 1) * radius, latitude + (Math.random() * 2 - 1) * radius];
// }

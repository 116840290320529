import AvatarWithStatus from "@/components/AvatarWithStatus";
import Loading from "@/components/Loading/Index";
import { client } from "@/config";
import ModalForm from "@/elements/ModalForm";
import UseFirebase from "@/hooks/firebase/useFirebase";
import UseStorage from "@/hooks/useStorage";
import { AppContext } from "@/models/AppStateProvider";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { UxContext } from "@/models/UxStateProvider";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, FormLabel, List, ListItemButton, Stack, Switch, Typography, useTheme } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useContext, useState } from "react";

export default function WorkspaceUserProfile() {
  const { flags, setFlags } = React.useContext(AppContext);
  const { myAccount } = React.useContext(MyAccountContext);
  const { setUserFeedbackModalOpen } = React.useContext(UxContext);
  const { myCurrentWorkspaceMembership } = useContext(WorkspaceContext);
  const [loggingOut, setLoggingOut] = useState<boolean>(false);
  const { showDebugMenu, allowLocalUserSettings } = useFlags();
  const { deleteActiveToken } = UseFirebase();
  const { clearLocalStorage } = UseStorage();
  const theme = useTheme();
  const { hideDriverMessages, setHideDriverMessages } = useContext(UxContext);

  const role = myCurrentWorkspaceMembership?.role;
  const showLocalUserSettingsMenu = allowLocalUserSettings && role === "member";

  const handleFlag = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFlags({ ...flags, [event.target.name]: event.target.checked });
  };

  const handleSubmitFeedback = () => {
    setUserFeedbackModalOpen(true);
  };

  const handleLogout = async () => {
    setLoggingOut(() => true);
    await deleteActiveToken();
    clearLocalStorage();
    return client.logout();
  };

  const readableFlags = Object.keys(flags).map((flag) => ({
    key: flag,
    text: flag.replace(/([a-z])([A-Z])/g, "$1 $2"),
  }));

  const profileActions = [
    {
      text: "Submit Feedback",
      key: "submit-feedback",
      action: handleSubmitFeedback,
      icon: <KeyboardArrowRightIcon />,
    },
    {
      text: "Sign Out",
      key: "sign-out",
      action: handleLogout,
      icon: <ExitToAppIcon />,
    },
  ];

  return (
    <>
      <ModalForm open={loggingOut} allowClose={false}>
        <Box sx={{ display: "flex" }}>
          <Loading variant="white" size="large" baseSize={6} /> <Box sx={{ ml: 2 }}>Signing Out</Box>
        </Box>
      </ModalForm>
      <Box sx={{ p: 2, pr: 0.75, position: "relative", height: "100%" }}>
        <Box sx={{ p: 2 }}>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: 1.5,
            }}
          >
            <AvatarWithStatus accountId={myAccount?.id} />
            <Typography sx={{ fontWeight: 500, fontSize: "1.25rem" }}>{myAccount?.name}</Typography>
          </Stack>
        </Box>

        <List sx={{ p: 0 }} className="scrollable-content">
          {profileActions.map((item) => {
            if (item) {
              return (
                <ListItemButton
                  key={item.key}
                  aria-label={`Navigation to ${item.text}`}
                  onClick={item.action}
                  disableRipple={true}
                  sx={{
                    width: "100%",
                    borderRadius: "8px",
                    justifyContent: "space-between",
                    wordBreak: "break-word",
                    p: 2,
                    fontSize: "1.125rem",
                    fontWeight: 500,
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      textAlign: "left",
                      justifyContent: "start",
                    }}
                  >
                    {item.text}
                  </Box>
                  <Box
                    sx={{
                      flex: "0 1 auto",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item.icon}
                  </Box>
                </ListItemButton>
              );
            }
          })}

          {showLocalUserSettingsMenu && (
            <Box sx={{ mt: 4 }}>
              <Box
                component="button"
                className="basic-button no-hover"
                sx={{ display: "flex", textAlign: "left", alignItems: "start", gap: 2, ml: 2 }}
                onClick={() => setHideDriverMessages((value) => !value)}
              >
                <Box>
                  <Switch checked={hideDriverMessages} />
                </Box>

                <Box>
                  <Box>Team Dispatching Mode</Box>
                  <Box sx={{ fontSize: "14px", color: theme.palette.neutral.main }}>
                    When ON, messages from drivers will not show as unread if another dispatcher sees them before me.
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          {showDebugMenu ? (
            <Box sx={{ p: 1, borderRadius: 1 }}>
              <Typography variant="h5" component="h2" fontWeight={700}>
                Debug Menu
              </Typography>
              <Stack sx={{ p: 2, gap: 1 }}>
                {readableFlags.map((flag) => (
                  <FormLabel
                    sx={{
                      display: "flex",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                    key={flag.key}
                  >
                    <Switch checked={flags[flag.key]} onChange={handleFlag} name={flag.key} sx={{ mr: 2 }} />
                    <Box sx={{ textTransform: "capitalize" }}>{flag.text}</Box>
                  </FormLabel>
                ))}
              </Stack>
            </Box>
          ) : null}
        </List>
      </Box>
    </>
  );
}

import Client from "web-client/client";
import { trace } from "@opentelemetry/api";

interface EchoData {
  accountId: string;
  workspaceId: string;
  deviceRegistrationId: string;
  sentAt: number;
  status: "pending" | "success" | "failed";
  timeoutId: NodeJS.Timeout;
  echoIntervalMs: number;
  echoTimeoutMs: number;
}

// Map to store echo verification data
const echoMap = new Map<string, EchoData>();
let registeredClient: Client | null = null;
let echoIntervalId: NodeJS.Timeout | null = null;

function logEcho(echoData: EchoData) {
  const tracer = trace.getTracer("async-echo");
  const span = tracer.startSpan(`Echo results`);

  // Find the echo string by searching the map
  let echoString = "";
  for (const [key, value] of echoMap.entries()) {
    if (value === echoData) {
      echoString = key;
      break;
    }
  }

  span.setAttributes({
    echo: echoString,
    accountId: echoData.accountId,
    workspaceId: echoData.workspaceId,
    deviceRegistrationId: echoData.deviceRegistrationId,
    status: echoData.status,
    echoIntervalMs: echoData.echoIntervalMs,
    echoTimeoutMs: echoData.echoTimeoutMs,
  });
  span.end();
}

function createTimeoutHandler(echoString: string) {
  return () => {
    const echoData = echoMap.get(echoString);
    if (!echoData) return;

    if (echoData.status === "pending") {
      console.error("Echo verification failed - timeout after 30 seconds");
      echoData.status = "failed";
    }

    logEcho(echoData);
    echoMap.delete(echoString);
  };
}

// Function to start echo verification
function startEchoVerification(
  accountId: string,
  workspaceId: string,
  deviceRegistrationId: string,
  echoIntervalMs: number,
  echoTimeoutMs: number,
) {
  if (echoIntervalId) {
    clearInterval(echoIntervalId);
  }

  echoIntervalId = setInterval(() => {
    if (!registeredClient) {
      console.error("No client registered for echo verification");
      return;
    }

    sendNewEcho(accountId, workspaceId, deviceRegistrationId, echoIntervalMs, echoTimeoutMs);
  }, echoIntervalMs);
}

function sendNewEcho(
  accountId: string,
  workspaceId: string,
  deviceRegistrationId: string,
  echoIntervalMs: number,
  echoTimeoutMs: number,
) {
  console.log(`Sending new echo for ${accountId} ${workspaceId} ${deviceRegistrationId}`);
  const now = new Date();
  const newEchoString = `accountId:${accountId}-workspaceId:${workspaceId}-deviceRegistrationId:${deviceRegistrationId}-${now.toISOString()}`;

  const echoData: EchoData = {
    accountId,
    workspaceId,
    deviceRegistrationId,
    sentAt: now.getTime(),
    status: "pending",
    timeoutId: setTimeout(createTimeoutHandler(newEchoString), echoTimeoutMs),
    echoIntervalMs,
    echoTimeoutMs,
  };

  echoMap.set(newEchoString, echoData);
  registeredClient?.asyncEcho(newEchoString).catch((error) => {
    console.error(`Error sending echo for ${newEchoString}`, error);
  });
}

export function registerAppSyncEcho(
  client: Client,
  accountId: string,
  workspaceId: string,
  deviceRegistrationId: string,
  echoIntervalMs: number = 60000,
  echoTimeoutMs: number = 30000,
) {
  cleanup();
  registeredClient = client;
  startEchoVerification(accountId, workspaceId, deviceRegistrationId, echoIntervalMs, echoTimeoutMs);
}

export function handleEchoResponse(echoString: string) {
  console.log(`Handling echo response for ${echoString}`);
  const echoData = echoMap.get(echoString);
  if (!echoData) return;

  clearTimeout(echoData.timeoutId);
  echoData.status = "success";
  logEcho(echoData);
  echoMap.delete(echoString);
}

export function cleanup() {
  if (echoIntervalId) {
    clearInterval(echoIntervalId);
  }

  // Clear all timeouts and remove all entries
  for (const [_, data] of echoMap) {
    clearTimeout(data.timeoutId);
  }
  echoMap.clear();

  echoIntervalId = null;
  registeredClient = null;
}

import Client from "web-client/client";
import { setupAmpli } from "./models/TrackingStateProvider";
export type ConfigData = {
  baseDomain: string;
  tusUrl: string;
  graphqlUrl: string;
  graphqlApiKey: string;
  sessionRecordingLength: number;
  gaKey: string;
  env: string;
  trackingKey?: string;
  debugMode?: boolean;
};

export type FlagData = {
  debugMode?: boolean;
  recordingDebug?: boolean;
  listenOnly?: boolean;
  appPhone?: boolean;
  workspaces?: boolean;
  voiceAssistant?: boolean;
  sqlEditor?: boolean;
  allowCalls?: boolean;
  allowInboundCalls?: boolean;
};

export const config: ConfigData = {
  baseDomain: import.meta.env.VITE_BASE_DOMAIN,
  tusUrl: import.meta.env.VITE_TUS_URL,
  graphqlUrl: import.meta.env.VITE_GRAPHQL_URL,
  graphqlApiKey: import.meta.env.VITE_GRAPHQL_API_KEY,
  sessionRecordingLength: Number(import.meta.env.VITE_SESSION_RECORDING_LENGTH),
  gaKey: import.meta.env.VITE_GA_KEY,
  env: import.meta.env.MODE,
  trackingKey: import.meta.env.VITE_TRACKING_KEY ?? "",
  debugMode: import.meta.env.VITE_DEBUG_MODE === "true",
};

declare const window: Window & { dataLayer: Record<string, unknown>[] };
export const debugFromUrl = new URLSearchParams(window.location.search).get("debug");
if (debugFromUrl) {
  config.debugMode = true;
  console.log("Force setting debug mode");
}

export const flags: FlagData = {
  debugMode: config.debugMode,
  recordingDebug: false,
  listenOnly: true,
  appPhone: true,
  workspaces: config.debugMode,
  voiceAssistant: false,
  sqlEditor: false,
  allowCalls: config.debugMode,
  allowInboundCalls: config.debugMode,
};

export const ampli = setupAmpli(config.trackingKey);

export const client = new Client({
  baseDomain: config.baseDomain,
  logout: (returnTo?: string) => {
    ampli.client.setUserId(null);
    if (returnTo) {
      window.location.href = `${config.baseDomain}/auth/logout?returnTo=${returnTo}`;
    } else {
      window.location.href = `${config.baseDomain}/auth/logout`;
    }
  },
  logoutLink: (returnTo?: string) => {
    return `${config.baseDomain}/auth/logout${returnTo ? `?returnTo=${returnTo}` : ""}`;
  },
  login: () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: {
          name: "authScreen",
          value: "Auth0 App: SMS",
        },
      });
    }
    ampli.authScreen({ "Auth0 App": "SMS" });
    const origin = window.location.origin;
    const appPath = encodeURIComponent(`${window.location.pathname}${window.location.search}`);
    const returnTo = `${origin}/authSuccess?returnTo=${appPath}`;
    window.location.href = `${config.baseDomain}/auth/login?returnTo=${returnTo}`;
  },
});

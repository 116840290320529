import { live } from "@electric-sql/pglite/live";
import { auto_explain } from "@electric-sql/pglite/contrib/auto_explain";
import { drizzle } from "drizzle-orm/pglite";
import * as schema from "./schema.js";

const AUTO_EXPLAIN_ENABLED = true;

import { PGlite } from "@electric-sql/pglite";
const client = AUTO_EXPLAIN_ENABLED
  ? await PGlite.create({
      debug: 0,
      relaxedDurability: true,
      extensions: { live, auto_explain },
    })
  : await PGlite.create({
      debug: 0,
      relaxedDurability: true,
      extensions: { live },
    });

const db = drizzle(client, { schema });

if (AUTO_EXPLAIN_ENABLED) {
  await db.execute(`LOAD 'auto_explain'`);

  // Log queries taking longer than 100ms
  await db.execute(`SET auto_explain.log_min_duration = '100ms'`);
  // Include actual execution time
  await db.execute(`SET auto_explain.log_analyze = true`);
  // Include buffer usage statistics
  await db.execute(`SET auto_explain.log_buffers = true`);
  // Include detailed timing information
  await db.execute(`SET auto_explain.log_timing = true`);

  await db.execute(`SET auto_explain.log_level = 'WARNING'`);

  await db.execute(`SET auto_explain.log_format = 'json'`);
}

export { db, client };

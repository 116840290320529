import AvatarWithStatus from "@/components/AvatarWithStatus";
import FeedAliasChannelStatus from "@/components/FeedAliasChannelStatus";
import { AccountEvent } from "@/db/types";
import ModalForm from "@/elements/ModalForm";
import { FeedContext, FullItem } from "@/models/FeedContextProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { AccountInfo } from "@/models/accountInfo";
import { Done, DoneAll, FileDownloadOutlined, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import HearingIcon from "@mui/icons-material/Hearing";
import HearingDisabledIcon from "@mui/icons-material/HearingDisabled";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import {
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import Papa from "papaparse";
import React, { forwardRef, useImperativeHandle } from "react";
import { useToggle } from "usehooks-ts";

export type Status = {
  account: AccountInfo;
  started?: AccountEvent;
  completed?: AccountEvent;
  read?: AccountEvent;
  playedWhileDriving?: AccountEvent;
  playedUnknown?: AccountEvent;
  deviceMuted?: AccountEvent;
  seenByDriver?: AccountEvent;
};

type StatusGroup = {
  title: string;
  statuses: AccountInfo[] | Status[];
  statusCount: number;
  show: boolean;
  toggle: Function;
  color?: string;
  icon?: React.ReactNode;
};

const FeedItemStatus = forwardRef(
  (
    {
      sender,
      item,
      statuses,
      aliasChannelOwnerId,
    }: {
      sender: AccountInfo;
      item: FullItem;
      statuses: Status[];
      aliasChannelOwnerId?: string;
    },
    ref?: React.MutableRefObject<any>,
  ) => {
    const { ampli } = React.useContext(TrackingContext);
    const { itemMessageDetailsState, setItemMessageDetailsState } = React.useContext(FeedContext);
    const messageDetailsOpen = itemMessageDetailsState[item?.id] || false;

    const [showSent, showSentToggle] = useToggle(true);
    const [showCompleted, showCompletedToggle] = useToggle(true);
    const [showStarted, showStartedToggle] = useToggle(true);
    const [showRead, showReadToggle] = useToggle(true);
    const [showHeardByDriver, showHeardByDriverToggle] = useToggle(true);
    const [showPlayedUnknown, showPlayedUnknownToggle] = useToggle(true);
    const [showSeenByDriver, showSeenByDriverToggle] = useToggle(true);
    const [showDeviceMuted, showDeviceMutedToggle] = useToggle(true);

    const theme = useTheme();

    const formatStatuses = (statuses: Status[], type: string) => {
      return statuses?.map((status) => {
        return {
          account: status?.account,
          createdAt: status?.[type]?.createdAt,
        };
      });
    };

    const completed = formatStatuses(
      statuses?.filter((status) => status?.completed),
      "completed",
    );

    const read = formatStatuses(
      statuses?.filter((status) => status?.read && !aliasChannelOwnerId),
      "read",
    );

    const playedWhileDriving = formatStatuses(
      statuses?.filter((status) => {
        return (
          status?.playedWhileDriving &&
          status?.playedWhileDriving.name.toLowerCase().includes("listen") &&
          !status?.deviceMuted
        );
      }),
      "read",
    );

    const playedUnknown = formatStatuses(
      statuses?.filter((status) => {
        return (
          status?.playedUnknown &&
          !status?.playedWhileDriving &&
          aliasChannelOwnerId &&
          status.playedUnknown.name.toLowerCase().includes("listen") &&
          !status?.deviceMuted
        );
      }),
      "playedUnknown",
    );

    const seenByDriver = formatStatuses(
      statuses?.filter((status) => status?.read && aliasChannelOwnerId),
      "read",
    );

    const deviceMuted = formatStatuses(
      statuses?.filter((status) => status?.read && aliasChannelOwnerId && status.deviceMuted),
      "deviceMuted",
    );

    const started = formatStatuses(
      statuses?.filter((status) => {
        if (status?.completed) return false;
        return status?.started;
      }),
      "started",
    );

    const completionEvents = statuses?.reduce((acc, status) => {
      if ((status?.started && !status?.completed) || status?.completed || status?.read) {
        return acc + 1;
      }

      return acc;
    }, 0);

    const handleOpen = React.useCallback(() => {
      setItemMessageDetailsState((state) => ({
        ...state,
        [item?.id]: true,
      }));
      ampli.viewMessageDetails();
    }, [ampli, item, setItemMessageDetailsState]);

    const handleClose = () => {
      setItemMessageDetailsState((state) => ({
        ...state,
        [item?.id]: false,
      }));
    };

    const getFormattedTime = (dateString: string) => {
      const date = new Date(dateString);
      return dateString ? format(date, "MM/dd/yy hh:mm aaa") : "";
    };

    const handleCSVDownload = () => {
      const csv = Papa.unparse(
        statuses.map((status) => {
          return {
            name: status?.account?.name || `${status?.account?.firstName} ${status?.account?.lastName}`,
            "Listen: Completed": getFormattedTime(status?.completed?.createdAt),
            "Listen: Started": !status?.completed ? getFormattedTime(status?.started?.createdAt) : "",
            Read: getFormattedTime(status?.read?.createdAt),
          };
        }),
      );
      if (csv) {
        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document?.createElement("a");
        if (url) {
          a.href = url;
          a.download = `${item?.id}-${item?.feedId}-message-details-${new Date().toISOString()}.csv`;
          a?.click();
          URL.revokeObjectURL(url);
        }
      }
    };

    useImperativeHandle(
      ref,
      () => ({
        open() {
          handleOpen();
        },
        close() {
          handleClose();
        },
      }),
      [handleOpen, handleClose],
    );

    const statusGroups = [
      {
        title: "Sent",
        statuses: [sender],
        statusCount: 0,
        show: showSent,
        toggle: showSentToggle,
        color: theme.palette.neutral.main,
        icon: <Done role="img" fontSize="small" />,
      },
      {
        title: "Listened",
        statuses: completed,
        statusCount: completed?.length,
        show: showCompleted,
        toggle: showCompletedToggle,
        icon: <DoneAll role="img" fontSize="small" />,
      },
      {
        title: "Started listening",
        statuses: started,
        statusCount: started?.length,
        show: showStarted,
        toggle: showStartedToggle,
        icon: <DoneAll role="img" fontSize="small" />,
      },
      {
        title: "Read",
        statuses: read,
        statusCount: read?.length,
        show: showRead,
        toggle: showReadToggle,
        icon: <DoneAll role="img" fontSize="small" />,
      },
      {
        title: "Message heard by driver",
        statuses: playedWhileDriving,
        statusCount: read?.length,
        show: showHeardByDriver,
        toggle: showHeardByDriverToggle,
        icon: <HearingIcon role="img" fontSize="small" />,
      },
      {
        title: "Message played but driver may not be present",
        statuses: playedUnknown,
        statusCount: read?.length,
        show: showPlayedUnknown,
        toggle: showPlayedUnknownToggle,
        icon: <HearingDisabledIcon role="img" fontSize="small" />,
      },
      {
        title: "Message seen by driver",
        statuses: seenByDriver,
        statusCount: read?.length,
        show: showSeenByDriver,
        toggle: showSeenByDriverToggle,
        icon: <MarkChatReadIcon role="img" fontSize="small" />,
      },
      {
        title: "Device volume was muted when message arrived",
        statuses: deviceMuted,
        statusCount: read?.length,
        show: showDeviceMuted,
        toggle: showDeviceMutedToggle,
        icon: <VolumeOffIcon role="img" fontSize="small" />,
      },
    ] as StatusGroup[];

    const StatusListHeader = ({
      children,
      callback,
      titleColor = theme.palette.primary.main,
    }: {
      children: React.ReactNode;
      callback: Function;
      titleColor?: string;
    }) => (
      <ListSubheader
        sx={{
          background: theme.palette.primary.dark,
          p: 0,
          borderBottom: `1px solid ${theme.palette.secondary.main}`,
        }}
      >
        <ListItemButton
          onClick={() => callback()}
          sx={{
            padding: "12px 44px",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: titleColor,
              width: "100%",
            }}
            component="div"
          >
            {children}
          </Typography>
        </ListItemButton>
      </ListSubheader>
    );

    const StatusListItem = ({
      account,
      statusDate,
    }: {
      account: AccountInfo;
      statusDate: string;
    }) => {
      return (
        <ListItem
          className="status-list-item"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "12px 44px",
            background: theme.palette.neutral.dark,
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <AvatarWithStatus accountId={account.id} key={account?.id} />
            <Typography fontWeight={500}>{account?.name}</Typography>
          </Box>
          <Box>{getFormattedTime(statusDate)}</Box>
        </ListItem>
      );
    };

    const modalForm = () => {
      return (
        <ModalForm
          id={`message-details-${item?.id}`}
          open={messageDetailsOpen}
          onClose={handleClose}
          sx={{
            padding: { xs: "0", sm: "0 0 12px 0" },
            overflow: "hidden !important",
          }}
        >
          <Stack
            sx={{
              alignItems: "flex-start",
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                padding: "12px 44px",
                position: "sticky",
                top: 0,
                zIndex: 1100,
                background: theme.palette.secondary.dark,
                gap: 1,
              }}
            >
              <Typography variant="h6" component="h2">
                Message Details
              </Typography>
              <Button
                variant="text"
                sx={{
                  fontWeight: 500,
                  maxWidth: "max-content",
                  px: 1,
                  py: 0.25,
                  fontSize: "0.875rem",
                  border: `1px solid ${theme.palette.info.light}`,
                  lineHeight: "1",
                  gap: "4px",
                }}
                onClick={handleCSVDownload}
              >
                <FileDownloadOutlined role="presentation" sx={{ width: 18, height: 18 }} />
                CSV
              </Button>
            </Box>
            <Box sx={{ pl: 1.25, pb: 1, width: "100%" }}>
              <List
                sx={{
                  width: "100%",
                  position: "relative",
                  overflow: "auto",
                  maxHeight: { sm: 450 },
                  "& ul": { padding: 0 },
                  borderRadius: "4px",
                  "& .status-list-section:last-child": {
                    borderBottomRightRadius: "4px",
                    borderBottomLeftRadius: "4px",
                    ".status-list-item:last-child": {
                      border: "none",
                    },
                  },
                  p: 0,
                }}
                subheader={<li />}
              >
                {statusGroups.map((group) => {
                  if (group.statuses?.length === 0) return;
                  return (
                    <li className="status-list-section" key={`section-${item?.id}-${group.title}`}>
                      <ul key={`section-${item?.id}-${group.title}-events`}>
                        <StatusListHeader callback={() => group.toggle()} titleColor={group.color}>
                          <Stack
                            flexDirection={"row"}
                            sx={{
                              flexDirection: "row",
                              gap: 1,
                              alignItems: "center",
                            }}
                          >
                            {group?.icon}
                            {group.title}
                            {group.statuses?.length === 0 || group.title === "Sent"
                              ? null
                              : ` (${group.statuses?.length})`}
                          </Stack>
                          {group.show ? <KeyboardArrowUp role="img" /> : <KeyboardArrowDown role="img" />}
                        </StatusListHeader>
                        <Collapse in={group.show}>
                          {group.statuses?.map((status, index) => {
                            if (status && Object.keys(status).includes("accountType")) {
                              return (
                                <StatusListItem key={`status_${index}`} account={status} statusDate={item?.createdAt} />
                              );
                            }
                            return (
                              <StatusListItem
                                key={`status_${index}`}
                                account={status?.account}
                                statusDate={status?.createdAt}
                              />
                            );
                          })}
                        </Collapse>
                      </ul>
                    </li>
                  );
                })}
              </List>
            </Box>
          </Stack>
        </ModalForm>
      );
    };

    const hideIfCallRecordsExist = item?.callRecords?.length > 0;

    if (aliasChannelOwnerId && !hideIfCallRecordsExist) {
      return (
        <>
          <FeedAliasChannelStatus onClick={handleOpen} statuses={statuses} aliasChannelOwnerId={aliasChannelOwnerId} />
          {modalForm()}
        </>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "4px",
        }}
      >
        {!hideIfCallRecordsExist && completionEvents > 0 ? (
          <Button aria-label="View message details" onClick={handleOpen}>
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "0.875rem",
                gap: "4px",
                fontWeight: 500,
              }}
            >
              <DoneAll fontSize="small" /> {completionEvents}
            </Typography>
          </Button>
        ) : (
          <Box sx={{ width: "36px", height: "36px", background: "transparent" }} />
        )}
        {modalForm()}
      </Box>
    );
  },
);

export default FeedItemStatus;

import type { AccountWithWsMembershipAvailability, MemberAvailability } from "@/db/types";
import {
  type TimeDifference,
  convertCamelCaseToCapitalize,
  convertSnakeCaseToNoCaseCapitalize,
  dateDiff,
  grayColor,
  greenColor,
  redColor,
  yellowColor,
} from "@/utils";

export type DeviceLocationType = {
  latitude?: number;
  longitude?: number;
  changedAt?: string;
  updatedAt?: string;
  municipality?: string;
  region?: string;
  country?: string;
  stale?: boolean;
};

export type TransformedWorkspaceMembershipAvailabilityRecord = {
  color: string;
  lastOnline?: string;
  drivingStatus?: string;
  dutyStatus?: string;
  availability: string;
  offline: boolean;
  status: string;
  toolTip: string;
  additionalInfo?: string[];
  deviceLocation: DeviceLocationType;
};

export type ExtraStatusType = {
  value: string;
  active: boolean;
  statusType: boolean;
};

export const isOffline = ({
  timestamp,
  offlineThreshold,
}: { timestamp: string | Date; offlineThreshold: number }): boolean => {
  const checkDateDiff: TimeDifference = dateDiff(timestamp, offlineThreshold);
  return checkDateDiff?.diffInMinutes >= offlineThreshold;
};

export const statusType = ({
  availability,
  offline,
}: { availability: MemberAvailability; offline: boolean }): string => {
  if (offline) {
    return "Offline";
  } else {
    switch (availability) {
      case "present":
        return "Driving";
      case "unknown":
        return "Unknown";
      case "notPresent":
        return "Not Driving";
      default:
        return "Unknown";
    }
  }
};

export const transformWorkspaceMembershipAvailabilityRecord = (
  wsMaR: AccountWithWsMembershipAvailability,
): TransformedWorkspaceMembershipAvailabilityRecord => {
  /**
   * HOS [Hours of service]
   */
  if (!wsMaR || Object?.keys(wsMaR)?.length === 0) {
    return;
  }

  const offlineThreshold: number = 10; // 10 minutes
  const handsFreeEnabled: boolean = wsMaR.mostRecentHandsFreeStatusEnabled === true;
  const muted: boolean = !(wsMaR.mostRecentVolumeAudible ?? true);
  const availability: string | null = wsMaR.calculatedAvailability;
  const dutyStatus: string | null = wsMaR.mostRecentDutyStatusStatus;
  const drivingStatus: string | null = wsMaR.mostRecentDrivingStatus
    ? wsMaR.mostRecentDrivingStatus === "active"
      ? "Driving"
      : "Not Driving"
    : null;

  const handsFreeChange = new Date(wsMaR.mostRecentHandsFreeStatusChangedAt);
  const volumeChange = new Date(wsMaR.mostRecentVolumeChangedAt);
  const dutyStatusChange = new Date(wsMaR.mostRecentDutyStatusChangedAt);
  const drivingStatusChange = new Date(wsMaR.mostRecentDrivingStatusChangedAt);
  //sort the dates and get the newest one, don't include dutyStatusChange if dutyStatus is null
  const sortedDate = [
    { key: "handsFreeChange", value: handsFreeChange },
    { key: "volumeChange", value: volumeChange },
    {
      key: "dutyStatusChange",
      value: dutyStatus ? dutyStatusChange : drivingStatusChange,
    },
  ]
    ?.filter((e) => e.value)
    ?.sort((a, b) => a.value.getTime() - b.value.getTime())
    ?.reverse();

  const offline = isOffline({ timestamp: wsMaR.updatedAt, offlineThreshold });

  const checkLastDateDiff = dateDiff(sortedDate[0].value, 1);

  const lastOnline: string = checkLastDateDiff?.lastOnline ? ` ${checkLastDateDiff.lastOnline}` : "";

  // Determine the color of the status based on the availability
  let color = "";
  if (offline) {
    color = grayColor;
  } else {
    switch (availability) {
      case "present":
        color = greenColor;
        break;
      case "unknown":
        color = yellowColor;
        break;
      case "notPresent":
        color = redColor;
        break;
      default:
        color = grayColor;
    }
  }

  // Status text that displays in the header and the tooltip
  let combinedStatus = "";
  if (offline) {
    combinedStatus = "Offline";
  } else {
    switch (availability) {
      case "present":
        combinedStatus = "Driving";
        break;
      default: {
        combinedStatus = dutyStatus ?? drivingStatus;
        // if the availability is unknown, And dutyStatus is not present, And drivingStatus is not present set as Not Driving
        if (combinedStatus === null) {
          combinedStatus = "Not Driving";
        }
      }
    }
  }

  // Change the casing of values like offDuty to Off Duty
  const combinedStatusFixedCasing =
    combinedStatus?.length > 0 ? convertCamelCaseToCapitalize(convertSnakeCaseToNoCaseCapitalize(combinedStatus)) : "";

  const extras: ExtraStatusType[] = [
    {
      value: combinedStatusFixedCasing,
      active: combinedStatusFixedCasing?.length > 0,
      statusType: true,
    },
    {
      value: lastOnline,
      active: lastOnline?.length > 0,
      statusType: true,
    },
    {
      value: "Hands-Free Mode Off",
      active: !handsFreeEnabled,
      statusType: false,
    },
    {
      value: "Device Muted",
      active: muted,
      statusType: false,
    },
  ].filter((extra) => extra.active);

  // Combine all extra attributes into a single tooltip string
  const toolTip: string = extras?.map((extra) => extra.value)?.join(" · ");

  // Array of extras: Muted | Hands-Free Mode Off
  const additionalInfo: string[] = extras?.filter((aI) => !aI.statusType)?.map((aI) => aI.value);
  const staleLocationData = dateDiff(wsMaR.mostRecentDeviceLocationUpdatedAt, 10);
  const oldLocation = staleLocationData.diffInHours >= 12;

  const deviceLocation = oldLocation
    ? {}
    : {
        latitude: wsMaR.mostRecentDeviceLocationLatitude,
        longitude: wsMaR.mostRecentDeviceLocationLongitude,
        changedAt: wsMaR.mostRecentDeviceLocationChangedAt,
        updatedAt: wsMaR.mostRecentDeviceLocationUpdatedAt,
        municipality: wsMaR.mostRecentDeviceLocationNearestAddressMunicipality,
        region: wsMaR.mostRecentDeviceLocationNearestAddressRegion,
        country: wsMaR.mostRecentDeviceLocationNearestAddressCountry,
        stale: staleLocationData.stale,
      };

  return {
    color,
    lastOnline,
    dutyStatus,
    drivingStatus,
    availability,
    offline,
    toolTip,
    additionalInfo,
    status: `${combinedStatusFixedCasing}${lastOnline}`,
    deviceLocation,
  };
};

import { Workspace, WorkspaceConfig } from "web-client/api/data-contracts";

const workspaceConfigs = new Map<string, WorkspaceConfig>();

export function updateWorkspaceConfig(workspace: Workspace) {
  workspaceConfigs.set(workspace.id, workspace.config);
}

function getWorkspaceConfig(workspaceId: string) {
  return workspaceConfigs.get(workspaceId);
}

export function handsFreeEnabledForWorkspace(workspaceId: string) {
  const config = getWorkspaceConfig(workspaceId);
  return config?.handsFree?.status === "all";
}

export function getWorkspaceLanguages(workspaceId: string) {
  const { allowedLanguages } = getWorkspaceConfig(workspaceId);
  return allowedLanguages ?? [];
}

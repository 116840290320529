import GeoTargetRecipients from "@/components/GeoTargetRecipients";
import PaperAirplane from "@/components/Icons/PaperAirplane";
import SelectRecipients from "@/components/SelectRecipients";
import TextMessageInput from "@/components/TextMessageInput";
import Locator from "@/locator";
import { ActionContext } from "@/models/ActionsProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UxContext } from "@/models/UxStateProvider";
import { pluralValue } from "@/utils";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Box, Button, Chip, Typography, useTheme } from "@mui/material";
import cuid from "cuid";
import { useContext, useState } from "react";

export default function FleetMessaging({ closeModal }: { closeModal: () => void }) {
  const {
    fleetMessageInvalidLocation,
    fleetMessageText,
    fleetMessageActiveChannels,
    setFleetMessageText,
    setFleetMessageActiveChannels,
  } = useContext(UxContext);
  const { currentWorkspaceId: workspaceId } = useContext(WorkspaceContext);
  const { ampli } = useContext(TrackingContext);
  const { publishBroadcast } = useContext(ActionContext);
  const theme = useTheme();
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState<string>("geo-target");
  const tabs = [
    {
      key: "geo-target",
      icon: <AddLocationAltIcon />,
      component: (
        <GeoTargetRecipients
          fleetMessageInvalidLocation={fleetMessageInvalidLocation}
          resetGeoValues={showSuccessMessage}
        />
      ),
      text: Locator.feed.input.fleetMessage.modal.geoLocation,
    },
    {
      key: "select-recipients",
      icon: <CheckBoxIcon />,
      component: <SelectRecipients />,
      text: Locator.feed.input.fleetMessage.modal.selectRecipients,
    },
  ].map((tab) => ({ ...tab, active: tab.key === activeTab }));
  const activeComponent = tabs.find((state) => state.active)?.component;
  const activeTabKey = tabs?.find((tab) => tab.active)?.key;

  const submitForm = async () => {
    const amplitudeEvent = () =>
      activeTabKey === "geo-target" ? ampli.sendGeoFleetMessage() : ampli.sendFleetMessage();

    setDisabledButton(() => true);
    const payload = {
      workspaceId,
      contentId: cuid(),
      feedIds: fleetMessageActiveChannels,
      inputText: fleetMessageText,
    };

    try {
      await publishBroadcast(payload);
      amplitudeEvent();
      setFleetMessageText("");
      setFleetMessageActiveChannels([]);
      setDisabledButton(() => false);
      setShowSuccessMessage(() => true);
      setFleetMessageText("");
      setTimeout(() => {
        setShowSuccessMessage(() => false);
      }, 4000);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const disabledState =
    fleetMessageActiveChannels?.length === 0 ||
    !fleetMessageText?.length ||
    disabledButton ||
    fleetMessageText?.length >= 5000;

  const messageLabelText = `${fleetMessageActiveChannels?.length} Recipient${pluralValue(fleetMessageActiveChannels?.length)}`;

  return (
    <Box sx={{ position: "relative" }}>
      {showSuccessMessage && (
        <Box
          onClick={() => setShowSuccessMessage(() => false)}
          sx={{
            display: "flex",
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            background: theme.palette.secondary.dark,
            zIndex: "100",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ py: 1, px: 2, background: theme.palette.success.light }}>Fleet Message Sent</Box>
        </Box>
      )}

      <Box sx={{ display: "flex", gap: 2, height: "450px" }}>
        <Box sx={{ width: "40%" }}>
          <TextMessageInput
            label={
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Message
                </Typography>

                {messageLabelText && (
                  <Chip
                    label={messageLabelText}
                    size="small"
                    sx={{
                      background:
                        fleetMessageActiveChannels?.length > 0
                          ? theme.palette.success.light
                          : theme.palette.secondary.light,
                    }}
                  />
                )}
              </Box>
            }
            rows={15}
            textInputValue={fleetMessageText}
            textInputHandler={(e) => setFleetMessageText(e)}
            showHint={false}
            required={true}
            aria-label={Locator.feed.input.fleetMessage.modal.tts}
          />
        </Box>
        <Box sx={{ width: "60%" }}>
          <Box component="ul" className="channel-types space-x-0.5">
            {tabs.map((tab) => (
              <Box component="li" key={tab.key}>
                <button
                  aria-label={tab.text}
                  style={{ padding: 6, gap: 12, display: "flex" }}
                  onClick={() => setActiveTab(tab.key)}
                  type="button"
                  className={`${tab.active ? "active-tab" : ""}`}
                >
                  <span className="icon">{tab.icon}</span>
                  <span className="text">{tab.text}</span>
                </button>
              </Box>
            ))}
          </Box>
          <Box sx={{ mt: 1 }}>{activeComponent}</Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", mt: 1, gap: 2 }}>
        <Box sx={{ width: "50%" }}>
          <Button
            variant="outlined"
            sx={{ order: { xs: 1, sm: 0 } }}
            onClick={() => closeModal()}
            aria-label={Locator.feed.input.fleetMessage.modal.cancel}
            type="button"
          >
            Cancel
          </Button>
        </Box>
        <Box sx={{ width: "50%" }}>
          <Button
            onClick={() => submitForm()}
            variant="contained"
            color="primary"
            sx={{ display: "flex", gap: 1 }}
            disabled={fleetMessageActiveChannels?.length === 0 || disabledState}
            type="button"
            aria-label={Locator.feed.input.fleetMessage.modal.continue}
          >
            <Box>Send</Box>
            <PaperAirplane
              style={{
                fill:
                  fleetMessageActiveChannels?.length === 0 || disabledState
                    ? theme.palette.neutral.main
                    : theme.palette.primary.main,
                height: "20px",
                width: "20px",
              }}
            />
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateWorkspaceDirectInvitationRequest,
  CreateWorkspaceDirectInvitationResponse,
  CreateWorkspaceScheduledBroadcastRequest,
  CreateWorkspaceScheduledBroadcastResponse,
  CreateWorkspaceTemplateRequest,
  CreateWorkspaceTemplateResponse,
  CreateWorkspaceWorkflowItemRequest,
  DeleteWorksapceFeedItemResponse,
  GetScheduledBroadcastsResponse,
  GetWorkspaceFeedEventsResponse,
  GetWorkspaceFeedItemsResponse,
  GetWorkspaceFeedPermissionsResponse,
  GetWorkspaceInvitationsResponse,
  GetWorkspaceTemplatesResponse,
  GetWorkspaceWorkflowItemsResponse,
  HandsFreeStatusRequest,
  HandsFreeStatusResponse,
  HeartbeatRequest,
  HeartbeatResponse,
  LocationsRequest,
  LocationsResponse,
  PublishBroadcastRequest,
  PublishBroadcastResponse,
  PublishToWorkspaceFeedRequest,
  PublishTTSRequest,
  PublishTTSResponse,
  PublishWorkspaceWorkflowItemRequest,
  UpdateFeedPreferenceByFeedGroupResponse,
  UpdateFeedPreferenceRequest,
  UpdateFeedPreferenceResponse,
  UpdateWorkspaceMembershipRequest,
  UpdateWorkspaceMembershipResponse,
  UpdateWorkspaceScheduledBroadcastRequest,
  UpdateWorkspaceScheduledBroadcastResponse,
  UpdateWorkspaceTemplateRequest,
  UpdateWorkspaceTemplateResponse,
  WorkspaceFeedItemContentRequest,
  WorkspaceFeedItemContentResponse,
  WorkspaceFeedItemLocationsRequest,
  WorkspaceFeedItemLocationsResponse,
  WorkspaceFeedItemOldestEventsRequest,
  WorkspaceFeedItemOldestEventsResponse,
  WsItem,
  WsPublishedWorkflowItem,
  WsWorkflowItem,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Workspaces<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name FeedsWorkspaceMembershipsCreate
   * @summary addWorkspaceMembersToFeed
   * @request POST:/workspaces/{workspaceId}/feeds/{feedId}/workspaceMemberships
   */
  feedsWorkspaceMembershipsCreate = (workspaceId: string, feedId: string, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}/workspaceMemberships`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name FeedItemsDetail
   * @summary bootstrapWorkspaceFeedItems
   * @request GET:/workspaces/{workspaceId}/feed/items
   */
  feedItemsDetail = (
    workspaceId: string,
    query: {
      page: number;
      pageSize?: number;
      itemId?: string;
      timestamp?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetWorkspaceFeedItemsResponse, any>({
      path: `/workspaces/${workspaceId}/feed/items`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name DirectInvitationsCreate
   * @summary createDirectworkspaceInvitation
   * @request POST:/workspaces/{workspaceId}/directInvitations
   */
  directInvitationsCreate = (
    workspaceId: string,
    body: CreateWorkspaceDirectInvitationRequest,
    params: RequestParams = {},
  ) =>
    this.request<CreateWorkspaceDirectInvitationResponse, any>({
      path: `/workspaces/${workspaceId}/directInvitations`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name TriggerScheduleCreate
   * @summary createScheduledBroadcast
   * @request POST:/workspaces/{workspaceId}/trigger/schedule
   */
  triggerScheduleCreate = (
    workspaceId: string,
    body: CreateWorkspaceScheduledBroadcastRequest,
    params: RequestParams = {},
  ) =>
    this.request<CreateWorkspaceScheduledBroadcastResponse, any>({
      path: `/workspaces/${workspaceId}/trigger/schedule`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkflowItemsCreate
   * @summary createWorkflowItem
   * @request POST:/workspaces/{workspaceId}/workflowItems
   */
  workflowItemsCreate = (workspaceId: string, body: CreateWorkspaceWorkflowItemRequest, params: RequestParams = {}) =>
    this.request<WsWorkflowItem, any>({
      path: `/workspaces/${workspaceId}/workflowItems`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkflowItemsDetail
   * @summary getWorkflowItems
   * @request GET:/workspaces/{workspaceId}/workflowItems
   */
  workflowItemsDetail = (workspaceId: string, params: RequestParams = {}) =>
    this.request<GetWorkspaceWorkflowItemsResponse, any>({
      path: `/workspaces/${workspaceId}/workflowItems`,
      method: "GET",
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspacesCreate
   * @summary createWorkspace
   * @request POST:/workspaces
   */
  workspacesCreate = (params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/workspaces`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name DirectMessagesCreate
   * @summary createWorkspaceDm
   * @request POST:/workspaces/{workspaceId}/direct-messages
   */
  directMessagesCreate = (workspaceId: string, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/workspaces/${workspaceId}/direct-messages`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsCreate
   * @summary createWorkspaceFeed
   * @request POST:/workspaces/{workspaceId}/feeds
   */
  feedsCreate = (workspaceId: string, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/workspaces/${workspaceId}/feeds`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name TemplatesCreate
   * @summary createWorkspaceTemplate
   * @request POST:/workspaces/{workspaceId}/templates
   */
  templatesCreate = (workspaceId: string, body: CreateWorkspaceTemplateRequest, params: RequestParams = {}) =>
    this.request<CreateWorkspaceTemplateResponse, any>({
      path: `/workspaces/${workspaceId}/templates`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name TemplatesDetail
   * @summary getWorkspaceTemplates
   * @request GET:/workspaces/{workspaceId}/templates
   */
  templatesDetail = (workspaceId: string, params: RequestParams = {}) =>
    this.request<GetWorkspaceTemplatesResponse, any>({
      path: `/workspaces/${workspaceId}/templates`,
      method: "GET",
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkflowItemsDelete
   * @summary deleteWorkflowItem
   * @request DELETE:/workspaces/{workspaceId}/workflowItems/{workflowItemId}
   */
  workflowItemsDelete = (workspaceId: string, workflowItemId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/workspaces/${workspaceId}/workflowItems/${workflowItemId}`,
      method: "DELETE",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name ItemsDelete
   * @summary deleteWorkspaceFeedItem
   * @request DELETE:/workspaces/{workspaceId}/{feedId}/items/{itemId}
   */
  itemsDelete = (workspaceId: string, feedId: string, itemId: string, params: RequestParams = {}) =>
    this.request<DeleteWorksapceFeedItemResponse, any>({
      path: `/workspaces/${workspaceId}/${feedId}/items/${itemId}`,
      method: "DELETE",
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name TemplatesDelete
   * @summary deleteWorkspaceTemplate
   * @request DELETE:/workspaces/{workspaceId}/templates/{templateId}
   */
  templatesDelete = (workspaceId: string, templateId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/workspaces/${workspaceId}/templates/${templateId}`,
      method: "DELETE",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name TemplatesPartialUpdate
   * @summary updateWorkspaceTemplate
   * @request PATCH:/workspaces/{workspaceId}/templates/{templateId}
   */
  templatesPartialUpdate = (
    workspaceId: string,
    templateId: string,
    body: UpdateWorkspaceTemplateRequest,
    params: RequestParams = {},
  ) =>
    this.request<UpdateWorkspaceTemplateResponse, any>({
      path: `/workspaces/${workspaceId}/templates/${templateId}`,
      method: "PATCH",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name HandsfreestatusCreate
   * @summary getHandsFreeStatus
   * @request POST:/workspaces/{workspaceId}/handsfreestatus
   */
  handsfreestatusCreate = (workspaceId: string, body: HandsFreeStatusRequest, params: RequestParams = {}) =>
    this.request<HandsFreeStatusResponse, any>({
      path: `/workspaces/${workspaceId}/handsfreestatus`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name LocationsCreate
   * @summary getLocations
   * @request POST:/workspaces/{workspaceId}/locations
   */
  locationsCreate = (workspaceId: string, body: LocationsRequest, params: RequestParams = {}) =>
    this.request<LocationsResponse, any>({
      path: `/workspaces/${workspaceId}/locations`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name ScheduledBroadcastsDetail
   * @summary getScheduledBroadcasts
   * @request GET:/workspaces/{workspaceId}/scheduledBroadcasts
   */
  scheduledBroadcastsDetail = (workspaceId: string, params: RequestParams = {}) =>
    this.request<GetScheduledBroadcastsResponse, any>({
      path: `/workspaces/${workspaceId}/scheduledBroadcasts`,
      method: "GET",
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsEventsDetail
   * @summary getWorkspaceFeedEvents
   * @request GET:/workspaces/{workspaceId}/feeds/{feedId}/events
   */
  feedsEventsDetail = (
    workspaceId: string,
    feedId: string,
    query: {
      page: number;
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetWorkspaceFeedEventsResponse, any>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}/events`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsItemsDetail
   * @summary getWorkspaceFeedItems
   * @request GET:/workspaces/{workspaceId}/feeds/{feedId}/items
   */
  feedsItemsDetail = (
    workspaceId: string,
    feedId: string,
    query: {
      page: number;
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetWorkspaceFeedItemsResponse, any>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}/items`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsPermissionsDetail
   * @summary getWorkspaceFeedPermissions
   * @request GET:/workspaces/{workspaceId}/feeds/{feedId}/permissions
   */
  feedsPermissionsDetail = (
    workspaceId: string,
    feedId: string,
    query: {
      page: number;
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetWorkspaceFeedPermissionsResponse, any>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}/permissions`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name InvitationsDetail
   * @summary getWorkspaceInvitations
   * @request GET:/workspaces/{workspaceId}/invitations
   */
  invitationsDetail = (workspaceId: string, params: RequestParams = {}) =>
    this.request<GetWorkspaceInvitationsResponse, any>({
      path: `/workspaces/${workspaceId}/invitations`,
      method: "GET",
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsLatestEventsDetail
   * @summary getWorkspaceLatestFeedEvents
   * @request GET:/workspaces/{workspaceId}/feeds/{feedId}/latest-events
   */
  feedsLatestEventsDetail = (
    workspaceId: string,
    feedId: string,
    query: {
      page: number;
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetWorkspaceFeedEventsResponse, any>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}/latest-events`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsOldestEventsDetail
   * @summary getWorkspaceOldestFeedEvents
   * @request GET:/workspaces/{workspaceId}/feeds/{feedId}/oldest-events
   */
  feedsOldestEventsDetail = (
    workspaceId: string,
    feedId: string,
    query: {
      page: number;
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetWorkspaceFeedEventsResponse, any>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}/oldest-events`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name HeartbeatCreate
   * @summary heartbeat
   * @request POST:/workspaces/{workspaceId}/heartbeat
   */
  heartbeatCreate = (workspaceId: string, body: HeartbeatRequest, params: RequestParams = {}) =>
    this.request<HeartbeatResponse, any>({
      path: `/workspaces/${workspaceId}/heartbeat`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedgroupsJoinCreate
   * @summary joinFeedGroup
   * @request POST:/workspaces/{workspaceId}/feedgroups/{feedGroupId}/join
   */
  feedgroupsJoinCreate = (workspaceId: string, feedGroupId: string, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/workspaces/${workspaceId}/feedgroups/${feedGroupId}/join`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsJoinUpdate
   * @summary joinWorkspaceFeed
   * @request PUT:/workspaces/{workspaceId}/feeds/{feedId}/join
   */
  feedsJoinUpdate = (workspaceId: string, feedId: string, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}/join`,
      method: "PUT",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name FeedgroupsLeaveCreate
   * @summary leaveFeedGroup
   * @request POST:/workspaces/{workspaceId}/feedgroups/{feedGroupId}/leave
   */
  feedgroupsLeaveCreate = (workspaceId: string, feedGroupId: string, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/workspaces/${workspaceId}/feedgroups/${feedGroupId}/leave`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsMuteUnmuteCreate
   * @summary muteUnmuteWorkspaceFeedMember
   * @request POST:/workspaces/{workspaceId}/feeds/{feedId}/mute-unmute
   */
  feedsMuteUnmuteCreate = (workspaceId: string, feedId: string, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}/mute-unmute`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsPromoteDemoteCreate
   * @summary promoteDemoteWorkspaceFeedMember
   * @request POST:/workspaces/{workspaceId}/feeds/{feedId}/promote-demote
   */
  feedsPromoteDemoteCreate = (workspaceId: string, feedId: string, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}/promote-demote`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name PublishBroadcastCreate
   * @summary publishBroadcast
   * @request POST:/workspaces/{workspaceId}/publishBroadcast
   */
  publishBroadcastCreate = (workspaceId: string, body: PublishBroadcastRequest, params: RequestParams = {}) =>
    this.request<PublishBroadcastResponse, any>({
      path: `/workspaces/${workspaceId}/publishBroadcast`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name PostWorkspaces
   * @summary publishTTS
   * @request POST:/workspaces/{workspaceId}/{feedId}/tts
   */
  postWorkspaces = (workspaceId: string, feedId: string, body: PublishTTSRequest, params: RequestParams = {}) =>
    this.request<PublishTTSResponse, any>({
      path: `/workspaces/${workspaceId}/${feedId}/tts`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name ItemsCreate
   * @summary publishToWorkspaceFeed
   * @request POST:/workspaces/{workspaceId}/{feedId}/items
   */
  itemsCreate = (
    workspaceId: string,
    feedId: string,
    body: PublishToWorkspaceFeedRequest,
    params: RequestParams = {},
  ) =>
    this.request<WsItem, any>({
      path: `/workspaces/${workspaceId}/${feedId}/items`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkflowItemsPublishCreate
   * @summary publishWorkflowItem
   * @request POST:/workspaces/{workspaceId}/workflowItems/{workflowItemId}/publish
   */
  workflowItemsPublishCreate = (
    workspaceId: string,
    workflowItemId: string,
    body: PublishWorkspaceWorkflowItemRequest,
    params: RequestParams = {},
  ) =>
    this.request<WsPublishedWorkflowItem, any>({
      path: `/workspaces/${workspaceId}/workflowItems/${workflowItemId}/publish`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsRemoveMemberCreate
   * @summary removeMemberFromFeed
   * @request POST:/workspaces/{workspaceId}/feeds/{feedId}/remove-member
   */
  feedsRemoveMemberCreate = (workspaceId: string, feedId: string, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}/remove-member`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspaceMembershipsRemoveDelete
   * @summary removeWorkspaceMember
   * @request DELETE:/workspaces/{workspaceId}/workspaceMemberships/remove
   */
  workspaceMembershipsRemoveDelete = (
    workspaceId: string,
    body: UpdateWorkspaceMembershipRequest,
    params: RequestParams = {},
  ) =>
    this.request<UpdateWorkspaceMembershipResponse, any>({
      path: `/workspaces/${workspaceId}/workspaceMemberships/remove`,
      method: "DELETE",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedItemOldestEventsCreate
   * @summary requestOldestFeedItemEvents
   * @request POST:/workspaces/{workspaceId}/feedItemOldestEvents
   */
  feedItemOldestEventsCreate = (
    workspaceId: string,
    body: WorkspaceFeedItemOldestEventsRequest,
    query?: {
      page?: number;
      pageSize?: number;
      timestamp?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<WorkspaceFeedItemOldestEventsResponse, any>({
      path: `/workspaces/${workspaceId}/feedItemOldestEvents`,
      method: "POST",
      query: query,
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedItemContentCreate
   * @summary requestWorkspaceFeedItemContent
   * @request POST:/workspaces/{workspaceId}/feedItemContent
   */
  feedItemContentCreate = (workspaceId: string, body: WorkspaceFeedItemContentRequest, params: RequestParams = {}) =>
    this.request<WorkspaceFeedItemContentResponse, any>({
      path: `/workspaces/${workspaceId}/feedItemContent`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedItemsLocationsCreate
   * @summary requestWorkspaceFeedItemLocations
   * @request POST:/workspaces/{workspaceId}/feedItems/locations
   */
  feedItemsLocationsCreate = (
    workspaceId: string,
    body: WorkspaceFeedItemLocationsRequest,
    params: RequestParams = {},
  ) =>
    this.request<WorkspaceFeedItemLocationsResponse, any>({
      path: `/workspaces/${workspaceId}/feedItems/locations`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name UnsubscribeFromFeedCreate
   * @summary unsubscribeFromFeed
   * @request POST:/workspaces/{workspaceId}/{feedId}/unsubscribeFromFeed
   */
  unsubscribeFromFeedCreate = (workspaceId: string, feedId: string, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/workspaces/${workspaceId}/${feedId}/unsubscribeFromFeed`,
      method: "POST",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsMembershipPreferencesPartialUpdate
   * @summary updateFeedPreference
   * @request PATCH:/workspaces/{workspaceId}/feeds/{feedId}/membership/{membershipId}/preferences
   */
  feedsMembershipPreferencesPartialUpdate = (
    workspaceId: string,
    feedId: string,
    membershipId: string,
    body: UpdateFeedPreferenceRequest,
    params: RequestParams = {},
  ) =>
    this.request<UpdateFeedPreferenceResponse, any>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}/membership/${membershipId}/preferences`,
      method: "PATCH",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name FeedGroupsMembershipPreferencesPartialUpdate
   * @summary updateFeedPreferencesForFeedGroup
   * @request PATCH:/workspaces/{workspaceId}/feed-groups/{feedGroupId}/membership/{membershipId}/preferences
   */
  feedGroupsMembershipPreferencesPartialUpdate = (
    workspaceId: string,
    feedGroupId: string,
    membershipId: string,
    body: UpdateFeedPreferenceRequest,
    params: RequestParams = {},
  ) =>
    this.request<UpdateFeedPreferenceByFeedGroupResponse, any>({
      path: `/workspaces/${workspaceId}/feed-groups/${feedGroupId}/membership/${membershipId}/preferences`,
      method: "PATCH",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name TriggerScheduleUpdate
   * @summary updateScheduledBroadcast
   * @request PUT:/workspaces/{workspaceId}/trigger/schedule/{scheduleId}
   */
  triggerScheduleUpdate = (
    workspaceId: string,
    scheduleId: string,
    body: UpdateWorkspaceScheduledBroadcastRequest,
    params: RequestParams = {},
  ) =>
    this.request<UpdateWorkspaceScheduledBroadcastResponse, any>({
      path: `/workspaces/${workspaceId}/trigger/schedule/${scheduleId}`,
      method: "PUT",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspacesPartialUpdate
   * @summary updateWorkspace
   * @request PATCH:/workspaces/{workspaceId}
   */
  workspacesPartialUpdate = (workspaceId: string, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/workspaces/${workspaceId}`,
      method: "PATCH",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name FeedsPartialUpdate
   * @summary updateWorkspaceFeed
   * @request PATCH:/workspaces/{workspaceId}/feeds/{feedId}
   */
  feedsPartialUpdate = (workspaceId: string, feedId: string, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/workspaces/${workspaceId}/feeds/${feedId}`,
      method: "PATCH",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspaceMembershipsUpdatePartialUpdate
   * @summary updateWorkspaceMember
   * @request PATCH:/workspaces/{workspaceId}/workspaceMemberships/update
   */
  workspaceMembershipsUpdatePartialUpdate = (
    workspaceId: string,
    body: UpdateWorkspaceMembershipRequest,
    params: RequestParams = {},
  ) =>
    this.request<UpdateWorkspaceMembershipResponse, any>({
      path: `/workspaces/${workspaceId}/workspaceMemberships/update`,
      method: "PATCH",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}

import { Span, trace } from "@opentelemetry/api";
import React, { ReactNode, createContext, useContext, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

interface TelemetryContextProps {
  setAttribute: (key: string, value: string) => void;
  generateSpan: (props: { traceName?: string; spanName: string }) => Span;
  observeActionToPaint: (props: { action: string; target?: string }) => Span;
  trackAction: (props: { action: string; target?: string }, customAttributes?: Record<string, any>) => Span;
  finishAction: (props: { action: string; target?: string }, customAttributes?: Record<string, any>) => void;
}

export const TelemetryContext = createContext<TelemetryContextProps | undefined>(undefined);

const attributes = new Map<string, string>();
const activeSpans = new Map<string, Span>();

interface ItemTelemetry {
  feedId: string;
  id: string;
}
interface FeedTelemetry {
  id: string;
}

function targetForItem(item: ItemTelemetry) {
  return `feed#${item.feedId}\nitem#${item.id}`;
}
function targetForFeed(feed: FeedTelemetry) {
  return `feed#${feed.id}`;
}

export const actions = {
  feedNavigate: (feedId: string) => ({
    action: "feed.navigate",
    target: `feed#${feedId}`,
  }),

  feedItemTextLoading: (item: ItemTelemetry) => ({
    action: "feed.item.text.loading",
    target: targetForItem(item),
  }),

  feedItemAudioLoading: (item: ItemTelemetry) => ({
    action: "feed.item.audio.loading",
    target: targetForItem(item),
  }),

  feedItemTextTts: (item: ItemTelemetry) => ({
    action: "feed.item.text.tts",
    target: targetForItem(item),
  }),

  feedItemAudioTts: (item: ItemTelemetry) => ({
    action: "feed.item.audio.tts",
    target: targetForItem(item),
  }),

  feedInitial: (feed: FeedTelemetry) => ({
    action: "feed.initial",
    target: targetForFeed(feed),
  }),

  feedScroll: (feed: FeedTelemetry) => ({
    action: "feed.scroll",
    target: targetForFeed(feed),
  }),

  bootstrapLoad: () => ({ action: "bootstrap.load" }),

  bootstrapFull: () => ({ action: "bootstrap.full" }),

  bootstrapNetwork: (name: string, page: number) => ({
    action: "bootstrap.network",
    target: `${name}\npage ${page}`,
  }),

  bootstrapDbWrite: (name: string, page: number) => ({
    action: "bootstrap.dbwrite",
    target: `${name}\npage ${page}`,
  }),

  bootstrapAfterHooksInitialUnreadItems: () => ({
    action: "bootstrap.afterHooks.initialUnreadItems",
  }),
};

export const TelemetryProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState !== "visible") {
        for (const span of activeSpans.values()) {
          span.setAttribute("tabLostFocus", true);
        }
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const setAttribute = (key: string, value: string) => {
    attributes.set(key, value);
  };

  const trackAction = (
    { action, target }: { action: string; target?: string },
    customAttributes?: Record<string, any>,
  ) => {
    const spanName = `${action}#${target}`;
    if (activeSpans.has(spanName)) {
      return;
    }
    const tracer = trace.getTracer("action-tracer");
    const span = tracer.startSpan(`Action ${action}`);
    console.log("Action Start: ", {
      action,
      target,
    });
    span.setAttribute("action", action);
    if (target) span.setAttribute("target", target);
    if (customAttributes) {
      for (const [key, value] of Object.entries(customAttributes)) {
        span.setAttribute(key, value);
      }
    }
    activeSpans.set(spanName, span);
    return span;
  };

  const finishAction = (
    { action, target }: { action: string; target?: string },
    customAttributes: Record<string, any> = {},
  ) => {
    const spanName = `${action}#${target}`;
    if (activeSpans.has(spanName)) {
      console.log("Action Finish: ", {
        action,
        target,
        attributes,
      });

      const span = activeSpans.get(spanName);
      for (const [key, value] of attributes.entries()) {
        span.setAttribute(key, value);
      }
      for (const [key, value] of Object.entries(customAttributes)) {
        span.setAttribute(key, value);
      }
      span.end();
      activeSpans.delete(spanName);
    }
  };

  useEffect(() => {
    const workspaceId = params.workspaceId;
    const feedId = params.feedId;
    setAttribute("workspaceId", workspaceId);
    setAttribute("feedId", feedId);
  }, [params]);

  const generateSpan = ({
    traceName,
    spanName,
  }: {
    traceName?: string;
    spanName: string;
  }) => {
    const tracer = trace.getTracer(traceName || "default-tracer");
    const span = tracer.startSpan(spanName);
    for (const [key, value] of attributes.entries()) {
      span.setAttribute(key, value);
    }
    return span;
  };

  const observeActionToPaint = ({ action, target }: { action: string; target?: string }) => {
    const span = generateSpan({
      spanName: `Action ${action}`,
      traceName: "action-tracer",
    });
    const startTime = Date.now();
    span.setAttribute("action", action);
    if (target) span.setAttribute("target", target);
    console.log("Action Start: ", {
      action,
      target,
      startTime,
    });

    window.requestAnimationFrame(() => {
      const time = Date.now() - startTime;
      span.setAttribute("next-paint-time", time);
      span.end();
      console.log("Action: ", {
        action,
        target,
        time,
      });
    });

    return span;
  };

  useEffect(() => {
    const span = observeActionToPaint({
      action: "navigate",
      target: location.pathname,
    });
    span.setAttribute("pathname", location.pathname);
    return () => {
      if (!span.isRecording()) return;
      span.end();
    };
  }, [location]);

  useEffect(() => {
    const handleClick = (event) => {
      const target = `${event.target.tagName} ${event.target.textContent}`;
      observeActionToPaint({ action: "click", target });
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, []);

  return (
    <TelemetryContext.Provider
      value={{
        setAttribute,
        generateSpan,
        observeActionToPaint,
        trackAction,
        finishAction,
      }}
    >
      {children}
    </TelemetryContext.Provider>
  );
};

const useTelemetry = (): TelemetryContextProps => {
  const context = useContext(TelemetryContext);
  if (!context) {
    throw new Error("useTelemetry must be used within a TelemetryProvider");
  }
  return context;
};

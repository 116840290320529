import { db } from "@/db/db";
import { useDrizzleSelect } from "@/db/drizzleUtils";
import { feed, feedGroupMembership, item, permission } from "@/db/schema";
import { Feed, FeedGroup } from "@/db/types";
import { and, desc, eq, ne, or, sql } from "drizzle-orm";
import React, { createContext, useContext } from "react";
import { CurrentFeedContext } from "./StateProviders/currentFeedProvider";
import { WorkspaceContext } from "./StateProviders/workspaceProvider";
import { myActiveFeedsQuery } from "./commonQueries";

interface FeedWithGroup extends Feed {
  groupId?: string;
  latestActivity: string;
  feedId?: string;
  name?: string;
}
interface GroupWithFeeds extends FeedGroup {
  feeds: FeedWithGroup[];
}

type LiveQueries = {
  fetchNextActiveChannel?: (groupId?: string) => Promise<string>;
  myActiveFeeds?: Awaited<ReturnType<typeof myActiveFeedsQuery>>;
  myActiveFeedsFiltered?: Awaited<ReturnType<typeof myActiveFeedsQuery>>;
  workspaceMembershipId?: string | null;
};

export const LiveQueryContext = createContext<LiveQueries>({});
type Props = {
  children: React.ReactNode | React.ReactNode[];
};
const LiveQueriesProvider = ({ children }: Props) => {
  const { currentWorkspaceId: workspaceId, myCurrentWorkspaceMembership } = useContext(WorkspaceContext);
  const { currentFeedId: feedId } = useContext(CurrentFeedContext);

  // List of active feeds that the current user has permissions to
  const { rows: myActiveFeeds } = useDrizzleSelect(
    myActiveFeedsQuery({
      workspaceId,
      myCurrentWorkspaceMembershipId: myCurrentWorkspaceMembership?.id,
    }),
  );

  const { rows: myActiveFeedsFiltered } = useDrizzleSelect(
    myActiveFeedsQuery({
      workspaceId,
      myCurrentWorkspaceMembershipId: myCurrentWorkspaceMembership?.id,
      filterCommandAlias: true,
    }),
  );

  // Fetch the next active channel available to the user
  const fetchNextActiveChannel = async (groupId?: string): Promise<string> => {
    const realOrFakeFeedId = !feedId ? "NULL" : feedId;
    const realOrFakeGroupId = !groupId ? "NULL" : groupId;

    const activeChannel = await db
      .select({
        id: feed.id,
        feedId: permission.feedId,
        workspaceMembershipId: permission.workspaceMembershipId,
        enabled: permission.enabled,
        title: feed.title,
        groupId: feedGroupMembership.groupId,
        latestActivity: sql`GREATEST(
        coalesce(${feed.updatedAt}, DATE '0001-01-01'),
        coalesce(${item.createdAt}, DATE '0001-01-01')
      ) as latestActivity`,
      })
      .from(permission)
      .innerJoin(feed, eq(feed.id, permission.feedId))
      .leftJoin(feedGroupMembership, eq(feed.id, feedGroupMembership.feedId))
      .leftJoin(item, eq(feed.id, item.feedId))
      .where(
        and(
          eq(permission.workspaceMembershipId, myCurrentWorkspaceMembership?.id),
          eq(permission.enabled, true),
          eq(feed.workspaceId, workspaceId),
          ne(feed.id, realOrFakeFeedId),
          or(ne(feedGroupMembership.groupId, realOrFakeGroupId), eq(feedGroupMembership.groupId, null)),
        ),
      )
      .groupBy(
        feed.id,
        permission.feedId,
        permission.workspaceMembershipId,
        permission.enabled,
        feedGroupMembership.groupId,
        item.createdAt,
      )
      .orderBy(desc(sql`latestActivity`))
      .limit(1)
      .execute();

    if (activeChannel?.length === 0) {
      return `/workspaces/${workspaceId}`;
    }
    return `/workspaces/${workspaceId}/feeds/${activeChannel[0]?.id}`;
  };

  const LiveQueryState: LiveQueries = {
    fetchNextActiveChannel,
    myActiveFeeds,
    myActiveFeedsFiltered,
    workspaceMembershipId: myCurrentWorkspaceMembership?.id,
  };

  return <LiveQueryContext.Provider value={LiveQueryState}>{children}</LiveQueryContext.Provider>;
};

export default LiveQueriesProvider;

import UseStorage from "@/hooks/useStorage";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { SetStateType } from "@/utils";
import { useMediaQuery, useTheme } from "@mui/material";
import { useLocalStorage } from "@uidotdev/usehooks";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";

interface ToolBarProps {
  offsetWidth: number;
  offsetLeft: number;
}

export type UxState = {
  bothNavOpen?: boolean;
  editWorkspaceSettings?: boolean;
  inviteModalOpen?: boolean;
  navTitle?: string;
  isMdUp?: boolean;
  isSmUp?: boolean;
  leftNavOpen?: boolean;
  rightNavOpen?: boolean;
  recurringScheduleModalOpen?: boolean;
  scheduleMessageModalOpen?: boolean;
  setBothNavOpen: SetStateType<boolean>;
  setEditWorkspaceSettings: SetStateType<boolean>;
  setNavTitle: SetStateType<string>;
  setInviteModalOpen: SetStateType<boolean>;
  setLeftNavOpen: SetStateType<boolean>;
  setRightNavOpen: SetStateType<boolean>;
  setRecurringScheduleModalOpen: SetStateType<boolean>;
  setScheduleMessageModalOpen: SetStateType<boolean>;
  setUserFeedbackModalOpen: SetStateType<boolean>;
  setWorkspaceModalOpen: SetStateType<boolean>;
  toggleInviteModal: () => void;
  toggleLeftNav: () => void;
  toggleRightNav: () => void;
  toggleChannelUsersModal: () => void;
  toggleWorkspaceModal: () => void;
  userFeedbackModalOpen?: boolean;
  workspaceModalOpen?: boolean;
  setGlobalSearchOpen?: SetStateType<boolean>;
  globalSearchOpen?: boolean;
  toolBarSize?: ToolBarProps;
  setToolbarSize?: SetStateType<ToolBarProps>;
  userReadOnlyMode?: boolean;
  setUserReadOnlyMode?: SetStateType<boolean>;
  fleetMessageModalOpen?: boolean;
  setFleetMessageModalOpen?: SetStateType<boolean>;
  fleetMessageText?: string;
  setFleetMessageText?: SetStateType<string>;
  fleetMessageActiveChannels?: string[];
  setFleetMessageActiveChannels?: SetStateType<string[]>;
  fleetMessageLocation?: string;
  setFleetMessageLocation?: SetStateType<string>;
  fleetMessageRadius?: string;
  setFleetMessageRadius?: SetStateType<string>;
  fleetMessageLatitude?: string;
  setFleetMessageLatitude?: SetStateType<string>;
  fleetMessageLongitude?: string;
  setFleetMessageLongitude?: SetStateType<string>;
  fleetMessageFromItem?: boolean;
  setFleetMessageFromItem?: SetStateType<boolean>;
  resetFleetValues?: () => void;
  hideDriverMessages?: boolean;
  setHideDriverMessages?: SetStateType<boolean>;
  fleetMessageInvalidLocation?: boolean;
  setFleetMessageInvalidLocation?: SetStateType<boolean>;
};

//create a context, with createContext api
export const UxContext = createContext<UxState>({
  setBothNavOpen: () => {},
  setEditWorkspaceSettings: () => {},
  setNavTitle: () => {},
  setInviteModalOpen: () => {},
  setLeftNavOpen: () => {},
  setRightNavOpen: () => {},
  setFleetMessageModalOpen: () => {},
  setFleetMessageText: () => {},
  setFleetMessageActiveChannels: () => {},
  setRecurringScheduleModalOpen: () => {},
  setScheduleMessageModalOpen: () => {},
  setUserFeedbackModalOpen: () => {},
  setWorkspaceModalOpen: () => {},
  toggleInviteModal: () => {},
  toggleLeftNav: () => {},
  toggleRightNav: () => {},
  toggleChannelUsersModal: () => {},
  toggleWorkspaceModal: () => {},
  setGlobalSearchOpen: () => {},
  setToolbarSize: () => {},
});

type Props = {
  children: ReactNode | ReactNode[];
};

const UxStateProvider = ({ children }: Props) => {
  const { ampli } = useContext(TrackingContext);

  const [leftNavOpen, setLeftNavOpen] = useState<boolean>(true);
  const [rightNavOpen, setRightNavOpen] = useState<boolean>(false);
  const [bothNavOpen, setBothNavOpen] = useState(leftNavOpen && rightNavOpen);
  const [inviteModalOpen, setInviteModalOpen] = useState<boolean>(false);
  const [channelUsersModalOpen, setChannelUsersModalOpen] = useState<boolean>(false);
  const [fleetMessageModalOpen, setFleetMessageModalOpen] = useState<boolean>(false);
  const [fleetMessageText, setFleetMessageText] = useState<string>("");
  const [fleetMessageActiveChannels, setFleetMessageActiveChannels] = useState<string[]>([]);
  const [scheduleMessageModalOpen, setScheduleMessageModalOpen] = useState<boolean>(false);
  const [workspaceModalOpen, setWorkspaceModalOpen] = useState<boolean>(false);
  const [editChannelSettings, setEditChannelSettings] = useState<boolean>(false);
  const [editWorkspaceSettings, setEditWorkspaceSettings] = useState<boolean>(false);
  const [navTitle, setNavTitle] = useState<string>("");
  const [userReadOnlyMode, setUserReadOnlyMode] = useState<boolean>(false);
  const [recurringScheduleModalOpen, setRecurringScheduleModalOpen] = useState<boolean>(false);
  const [userFeedbackModalOpen, setUserFeedbackModalOpen] = useState<boolean>(false);
  const [globalSearchOpen, setGlobalSearchOpen] = useState<boolean>(false);
  const [toolBarSize, setToolbarSize] = useState<ToolBarProps>({} as ToolBarProps);
  const [hideDriverMessages, setHideDriverMessages] = useLocalStorage("hideDriverMessages", false);

  const [fleetMessageLocation, setFleetMessageLocation] = useState<string>("");
  const [fleetMessageRadius, setFleetMessageRadius] = useState<string>("100");
  const [fleetMessageLatitude, setFleetMessageLatitude] = useState<string>("");
  const [fleetMessageLongitude, setFleetMessageLongitude] = useState<string>("");
  const [fleetMessageFromItem, setFleetMessageFromItem] = useState<boolean>(false);
  const [fleetMessageInvalidLocation, setFleetMessageInvalidLocation] = useState<boolean>(false);

  const resetFleetValues = () => {
    setFleetMessageLongitude(() => "");
    setFleetMessageLatitude(() => "");
    setFleetMessageRadius(() => "100");
    setFleetMessageLocation(() => "");
    setFleetMessageText(() => "");
    setFleetMessageModalOpen(() => false);
    setFleetMessageFromItem(() => false);
    setFleetMessageInvalidLocation(() => false);
    setFleetMessageActiveChannels(() => []);
  };

  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const toggleLeftNav = () => {
    const leftNavValue = !leftNavOpen;
    setLeftNavOpen(() => leftNavValue);
    setRightNavOpen(() => false);
    ampli.feedsListOpen();
  };

  const toggleRightNav = () => {
    const rightNavValue = !rightNavOpen;
    if (rightNavValue) {
      setLeftNavOpen(() => false);
    } else {
      setLeftNavOpen(() => true);
    }
    setRightNavOpen(() => rightNavValue);
  };

  useEffect(() => {
    // on screen size changes from large to medium, lets close both navs and only allow one open at a time
    if (!isMdUp) {
      setLeftNavOpen(false);
      setRightNavOpen(false);
    } else {
      setLeftNavOpen(true);
      setRightNavOpen(false);
    }
  }, [isMdUp]);

  const { getLocalStorage } = UseStorage();
  const localStorageUserReadOnlyMode = getLocalStorage("userReadOnlyMode");
  useEffect(() => {
    if (localStorageUserReadOnlyMode === true) {
      setUserReadOnlyMode(true);
    }
  }, []);

  const toggleInviteModal = () => {
    setInviteModalOpen(!inviteModalOpen);
  };

  const toggleChannelUsersModal = () => {
    setChannelUsersModalOpen(!channelUsersModalOpen);
  };

  const toggleWorkspaceModal = () => {
    setWorkspaceModalOpen(!workspaceModalOpen);
  };

  const uxState: UxState = {
    bothNavOpen,
    editWorkspaceSettings,
    fleetMessageModalOpen,
    inviteModalOpen,
    navTitle,
    isMdUp,
    isSmUp,
    leftNavOpen,
    rightNavOpen,
    recurringScheduleModalOpen,
    scheduleMessageModalOpen,
    setBothNavOpen,
    setEditWorkspaceSettings,
    setFleetMessageModalOpen,
    setInviteModalOpen,
    setLeftNavOpen,
    setRightNavOpen,
    setNavTitle,
    setRecurringScheduleModalOpen,
    setScheduleMessageModalOpen,
    setUserFeedbackModalOpen,
    setWorkspaceModalOpen,
    toggleInviteModal,
    toggleLeftNav,
    toggleRightNav,
    toggleChannelUsersModal,
    toggleWorkspaceModal,
    userFeedbackModalOpen,
    workspaceModalOpen,
    globalSearchOpen,
    setGlobalSearchOpen,
    toolBarSize,
    setUserReadOnlyMode,
    userReadOnlyMode,
    setToolbarSize,
    fleetMessageText,
    setFleetMessageText,
    fleetMessageActiveChannels,
    setFleetMessageActiveChannels,
    fleetMessageLocation,
    setFleetMessageLocation,
    fleetMessageRadius,
    setFleetMessageRadius,
    fleetMessageLatitude,
    setFleetMessageLatitude,
    fleetMessageLongitude,
    setFleetMessageLongitude,
    resetFleetValues,
    fleetMessageFromItem,
    setFleetMessageFromItem,
    hideDriverMessages,
    setHideDriverMessages,
    fleetMessageInvalidLocation,
    setFleetMessageInvalidLocation,
  };

  return <UxContext.Provider value={uxState}>{children}</UxContext.Provider>;
};

export default UxStateProvider;

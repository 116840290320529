/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull storyboard-web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/storyboard/Storyboard/implementation/storyboard-web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'development' | 'production';

export const ApiKey: Record<Environment, string> = {
  development: '25108a072060534c1c06e069811bc510',
  production: '72678d4a8072ffb6e2dce045c0853696'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '1',
    branch: 'main',
    source: 'storyboard-web',
    versionId: '403a472d-ea0f-4e8e-8ce8-e056358c3c00'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * Define which onboarding Cohort you're in
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | threeStepOnboarding, welcomeFeedOnboarding |
   */
  onboardingCohort?: "threeStepOnboarding" | "welcomeFeedOnboarding";
  pushNotificationsEnabled?: boolean;
  /**
   * This is a user property that will be added to all 3 surfaces to indicate whether a user has joined the RSVP link. We originally created this user property to track users joining via RSVP links.
   */
  RSVP?: boolean;
  /**
   * If a user joins via a modified RSVP link, such as when a user jions via Cohort A in tnto the welcome feed channel, we want to capture the information that they joined.
   */
  RSVPSource?: boolean;
  signedUpViaRsvp?: boolean;
  skipFirstFeedAndHeadStraightToWelcome?: boolean;
}

export interface AddNewUsersProperties {
  feedId?: string;
  teamName?: string;
}

export interface ApplicationBootTimeProperties {
  endTime: string;
  event: string;
  startTime: string;
  totalTime: string;
}

export interface AuthScreenProperties {
  /**
   * What version of auth is this? Pass any metadata string that would indicate to us what Auth0 app or method we're using.
   */
  "Auth0 App": string;
}

export interface AuthSuccessProperties {
  /**
   * What version of auth is this? Pass any metadata string that would indicate to us what Auth0 app or method we're using.
   */
  "Auth0 App": string;
}

export interface AutoplayRecordProperties {
  feedId?: string;
  handsFree?: boolean;
  itemId?: string;
}

export interface CopyFeedLinkProperties {
  feedId?: string;
  teamName?: string;
}

export interface CreateFirstFeedProperties {
  /**
   * This property should send with the feedCreate, createFirstFeed and feedOpen events
   */
  listenOnly?: boolean;
}

export interface CreateFleetMessageProperties {
  /**
   * Id related to the users workspace. Should be attached to all user events.
   */
  workspaceId?: string;
}

export interface DeleteRecordProperties {
  itemId?: string;
}

export interface DeleteTokenProperties {
  token: string;
}

export interface DesktopPushNotificationReceivedProperties {
  payload?: {
    data?: {
      accountId?: string;
      body?: string;
      channelId?: string;
      channelItemId?: string;
      contentId?: string;
      deviceToken?: string;
      icon?: string;
      link?: string;
      suggestedDescription?: string;
      suggestedTitle?: string;
      title?: string;
      type?: string;
      workspaceId?: string;
    };
    fcmMessageId?: string;
    from?: string;
    priority?: string;
  };
}

export interface FailedToRetrieveTokenProperties {
  error?: any;
}

export interface FeedbackProperties {
  /**
   * Id related to the users workspace. Should be attached to all user events.
   */
  workspaceId?: string;
}

export interface FeedCreateProperties {
  feedId?: string;
  /**
   * This property should send with the feedCreate, createFirstFeed and feedOpen events
   */
  listenOnly?: boolean;
  teamName?: string;
}

export interface FeedOpenProperties {
  feedId?: string;
  /**
   * This property should send with the feedCreate, createFirstFeed and feedOpen events
   */
  listenOnly?: boolean;
  referredAccountId?: string;
  /**
   * The account that referred the user to this feed
   */
  referringAccount?: string;
  teamName?: string;
}

export interface GroupCollapseProperties {
  /**
   * Id related to the users workspace. Should be attached to all user events.
   */
  workspaceId?: string;
}

export interface GroupExpandProperties {
  /**
   * Id related to the users workspace. Should be attached to all user events.
   */
  workspaceId?: string;
}

export interface GroupJoinProperties {
  /**
   * Id related to the users workspace. Should be attached to all user events.
   */
  workspaceId?: string;
}

export interface GroupLeaveProperties {
  /**
   * Id related to the users workspace. Should be attached to all user events.
   */
  workspaceId?: string;
}

export interface GroupMarkAllReadProperties {
  /**
   * Id related to the users workspace. Should be attached to all user events.
   */
  workspaceId?: string;
}

export interface HasTokenProperties {
  token: string;
}

export interface LocalProcessingErrorProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  duration: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  expectedDuration: number;
  message: string;
}

export interface OpenFleetMessageProperties {
  /**
   * Id related to the users workspace. Should be attached to all user events.
   */
  workspaceId?: string;
}

export interface OpenTranscriptProperties {
  feedId?: string;
  itemId?: string;
}

export interface PlayRecordProperties {
  feedId?: string;
  itemId?: string;
}

export interface PushNotificationProperties {
  payload?: any;
}

export interface SearchClickProperties {
  /**
   * Id related to the users workspace. Should be attached to all user events.
   */
  workspaceId?: string;
}

export interface SearchExecuteProperties {
  /**
   * Id related to the users workspace. Should be attached to all user events.
   */
  workspaceId?: string;
}

export interface SearchLinkToMessageProperties {
  /**
   * Id related to the users workspace. Should be attached to all user events.
   */
  workspaceId?: string;
}

export interface SendFeedbackProperties {
  /**
   * Id related to the users workspace. Should be attached to all user events.
   */
  workspaceId?: string;
}

export interface StartRecordProperties {
  feedId?: string;
  recordTrigger?: string;
}

export interface SubmitEmailInviteProperties {
  feedId?: string;
  teamName?: string;
}

export interface ToggleHandsFreeOffProperties {
  /**
   * Id related to the users workspace. Should be attached to all user events.
   */
  workspaceId?: string;
}

export interface ToggleHandsFreeOnProperties {
  /**
   * Id related to the users workspace. Should be attached to all user events.
   */
  workspaceId?: string;
}

export interface UpdateUserLanguageProperties {
  /**
   * Indicate the language the user chose when they switch the language of the transcription.  Use the string in the menu verbatim e.g. Español (MEX)
   */
  translationLanguage: string;
}

export interface UploadFailedProperties {
  feedId?: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActivateEmailSend implements BaseEvent {
  event_type = 'activateEmailSend';
}

export class ActivateListenOnly implements BaseEvent {
  event_type = 'activateListenOnly';
}

export class AddNewUsers implements BaseEvent {
  event_type = 'addNewUsers';

  constructor(
    public event_properties?: AddNewUsersProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddWorkspaceUser implements BaseEvent {
  event_type = 'AddWorkspaceUser';
}

export class AdminDeleteRecord implements BaseEvent {
  event_type = 'adminDeleteRecord';
}

export class ApplicationBootTime implements BaseEvent {
  event_type = 'applicationBootTime';

  constructor(
    public event_properties: ApplicationBootTimeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AppPhoneCreateNewNumber implements BaseEvent {
  event_type = 'appPhoneCreateNewNumber';
}

export class AppPhoneLinkCopied implements BaseEvent {
  event_type = 'appPhoneLinkCopied';
}

export class AppPhoneStart implements BaseEvent {
  event_type = 'appPhoneStart';
}

export class AudioFileUpload implements BaseEvent {
  event_type = 'audioFileUpload';
}

export class AudioProcessingTimeout implements BaseEvent {
  event_type = 'audioProcessingTimeout';
}

export class AuthScreen implements BaseEvent {
  event_type = 'authScreen';

  constructor(
    public event_properties: AuthScreenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AuthSuccess implements BaseEvent {
  event_type = 'authSuccess';

  constructor(
    public event_properties: AuthSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AutoplayRecord implements BaseEvent {
  event_type = 'autoplayRecord';

  constructor(
    public event_properties?: AutoplayRecordProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BluetoothRecordTrigger implements BaseEvent {
  event_type = 'bluetoothRecordTrigger';
}

export class BrowserCantGetToken implements BaseEvent {
  event_type = 'BrowserCantGetToken';
}

export class CompleteOnboardingDone implements BaseEvent {
  event_type = 'completeOnboardingDone';
}

export class CompleteOnboardingSkip implements BaseEvent {
  event_type = 'completeOnboardingSkip';
}

export class CopyFeedLink implements BaseEvent {
  event_type = 'copyFeedLink';

  constructor(
    public event_properties?: CopyFeedLinkProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateFirstFeed implements BaseEvent {
  event_type = 'createFirstFeed';

  constructor(
    public event_properties?: CreateFirstFeedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateFleetMessage implements BaseEvent {
  event_type = 'createFleetMessage';

  constructor(
    public event_properties?: CreateFleetMessageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateFleetMessageFromDriverMessage implements BaseEvent {
  event_type = 'createFleetMessageFromDriverMessage';
}

export class CreateWorkspace implements BaseEvent {
  event_type = 'createWorkspace';
}

export class DeactivateEmailSend implements BaseEvent {
  event_type = 'deactivateEmailSend';
}

export class DeleteAccount implements BaseEvent {
  event_type = 'deleteAccount';
}

export class DeleteRecord implements BaseEvent {
  event_type = 'deleteRecord';

  constructor(
    public event_properties?: DeleteRecordProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeleteToken implements BaseEvent {
  event_type = 'deleteToken';

  constructor(
    public event_properties: DeleteTokenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DesktopPushNotificationReceived implements BaseEvent {
  event_type = 'desktopPushNotificationReceived';

  constructor(
    public event_properties?: DesktopPushNotificationReceivedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DispatchCallAnswered implements BaseEvent {
  event_type = 'dispatchCallAnswered';
}

export class DispatchCallFailed implements BaseEvent {
  event_type = 'dispatchCallFailed';
}

export class DispatchCallPlaced implements BaseEvent {
  event_type = 'dispatchCallPlaced';
}

export class DispatchCallRinging implements BaseEvent {
  event_type = 'dispatchCallRinging';
}

export class DispatchCallTimeout implements BaseEvent {
  event_type = 'dispatchCallTimeout';
}

export class DowngradePermissionMember implements BaseEvent {
  event_type = 'downgradePermissionMember';
}

export class EditWorkspaceName implements BaseEvent {
  event_type = 'editWorkspaceName';
}

export class FailedToRetrieveToken implements BaseEvent {
  event_type = 'failedToRetrieveToken';

  constructor(
    public event_properties?: FailedToRetrieveTokenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Feedback implements BaseEvent {
  event_type = 'feedback';

  constructor(
    public event_properties?: FeedbackProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FeedCreate implements BaseEvent {
  event_type = 'feedCreate';

  constructor(
    public event_properties?: FeedCreateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FeedOpen implements BaseEvent {
  event_type = 'feedOpen';

  constructor(
    public event_properties?: FeedOpenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FeedsListOpen implements BaseEvent {
  event_type = 'feedsListOpen';
}

export class FileUploadChooseFile implements BaseEvent {
  event_type = 'fileUploadChooseFile';
}

export class FileUploadError implements BaseEvent {
  event_type = 'fileUploadError';
}

export class FileUploadInvoke implements BaseEvent {
  event_type = 'fileUploadInvoke';
}

export class FileUploadPhotoGallery implements BaseEvent {
  event_type = 'fileUploadPhotoGallery';
}

export class FileUploadSubmit implements BaseEvent {
  event_type = 'fileUploadSubmit';
}

export class GroupCollapse implements BaseEvent {
  event_type = 'groupCollapse';

  constructor(
    public event_properties?: GroupCollapseProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GroupExpand implements BaseEvent {
  event_type = 'groupExpand';

  constructor(
    public event_properties?: GroupExpandProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GroupJoin implements BaseEvent {
  event_type = 'groupJoin';

  constructor(
    public event_properties?: GroupJoinProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GroupLeave implements BaseEvent {
  event_type = 'groupLeave';

  constructor(
    public event_properties?: GroupLeaveProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GroupMarkAllRead implements BaseEvent {
  event_type = 'groupMarkAllRead';

  constructor(
    public event_properties?: GroupMarkAllReadProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HasToken implements BaseEvent {
  event_type = 'hasToken';

  constructor(
    public event_properties: HasTokenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InviteWorkspaceUser implements BaseEvent {
  event_type = 'inviteWorkspaceUser';
}

export class LinkUploadError implements BaseEvent {
  event_type = 'linkUploadError';
}

export class LinkUploadInvoke implements BaseEvent {
  event_type = 'linkUploadInvoke';
}

export class LinkUploadSubmit implements BaseEvent {
  event_type = 'linkUploadSubmit';
}

export class LocalProcessingError implements BaseEvent {
  event_type = 'localProcessingError';

  constructor(
    public event_properties: LocalProcessingErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoginViaLink implements BaseEvent {
  event_type = 'loginViaLink';
}

export class LogoutManually implements BaseEvent {
  event_type = 'logoutManually';
}

export class LogoutViaLink implements BaseEvent {
  event_type = 'logoutViaLink';
}

export class ManageUsers implements BaseEvent {
  event_type = 'manageUsers';
}

export class MembersListOpen implements BaseEvent {
  event_type = 'membersListOpen';
}

export class NoWorkspaceView implements BaseEvent {
  event_type = 'noWorkspaceView';
}

export class NoWorkspaceViewLogout implements BaseEvent {
  event_type = 'noWorkspaceViewLogout';
}

export class OpenFile implements BaseEvent {
  event_type = 'openFile';
}

export class OpenFleetMessage implements BaseEvent {
  event_type = 'openFleetMessage';

  constructor(
    public event_properties?: OpenFleetMessageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenLink implements BaseEvent {
  event_type = 'openLink';
}

export class OpenTranscript implements BaseEvent {
  event_type = 'openTranscript';

  constructor(
    public event_properties?: OpenTranscriptProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OutboundCallAnswered implements BaseEvent {
  event_type = 'outboundCallAnswered';
}

export class OutboundCallEnded implements BaseEvent {
  event_type = 'outboundCallEnded';
}

export class OutboundCallFail implements BaseEvent {
  event_type = 'outboundCallFail';
}

export class OutboundCallPlaced implements BaseEvent {
  event_type = 'outboundCallPlaced';
}

export class PlayRecord implements BaseEvent {
  event_type = 'playRecord';

  constructor(
    public event_properties?: PlayRecordProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PushNotification implements BaseEvent {
  event_type = 'pushNotification';

  constructor(
    public event_properties?: PushNotificationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RemoveWorkspaceAccess implements BaseEvent {
  event_type = 'RemoveWorkspaceAccess';
}

export class RevokePendingInvite implements BaseEvent {
  event_type = 'revokePendingInvite';
}

export class SearchClick implements BaseEvent {
  event_type = 'searchClick';

  constructor(
    public event_properties?: SearchClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchExecute implements BaseEvent {
  event_type = 'searchExecute';

  constructor(
    public event_properties?: SearchExecuteProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchLinkToMessage implements BaseEvent {
  event_type = 'searchLinkToMessage';

  constructor(
    public event_properties?: SearchLinkToMessageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendFeedback implements BaseEvent {
  event_type = 'sendFeedback';

  constructor(
    public event_properties?: SendFeedbackProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendFleetMessage implements BaseEvent {
  event_type = 'sendFleetMessage';
}

export class SendGeoFleetMessage implements BaseEvent {
  event_type = 'sendGeoFleetMessage';
}

export class ServiceworkerOfNotificationNotAvailable implements BaseEvent {
  event_type = 'Serviceworker of Notification not available';
}

export class StartRecord implements BaseEvent {
  event_type = 'startRecord';

  constructor(
    public event_properties?: StartRecordProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitEmailInvite implements BaseEvent {
  event_type = 'submitEmailInvite';

  constructor(
    public event_properties?: SubmitEmailInviteProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitFullName implements BaseEvent {
  event_type = 'submitFullName';
}

export class TextToSpeechMessageSend implements BaseEvent {
  event_type = 'textToSpeechMessageSend';
}

export class ToggleHandsFreeOff implements BaseEvent {
  event_type = 'toggleHandsFreeOff';

  constructor(
    public event_properties?: ToggleHandsFreeOffProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ToggleHandsFreeOn implements BaseEvent {
  event_type = 'toggleHandsFreeOn';

  constructor(
    public event_properties?: ToggleHandsFreeOnProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UpdateUserLanguage implements BaseEvent {
  event_type = 'updateUserLanguage';

  constructor(
    public event_properties: UpdateUserLanguageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UpgradePermissionAdmin implements BaseEvent {
  event_type = 'upgradePermissionAdmin';
}

export class UploadFailed implements BaseEvent {
  event_type = 'uploadFailed';

  constructor(
    public event_properties?: UploadFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UploadRecord implements BaseEvent {
  event_type = 'uploadRecord';
}

export class UploadTimeout implements BaseEvent {
  event_type = 'uploadTimeout';
}

export class ViewMessageContextMenu implements BaseEvent {
  event_type = 'viewMessageContextMenu';
}

export class ViewMessageDetails implements BaseEvent {
  event_type = 'viewMessageDetails';
}

export class ViewVoiceCommands implements BaseEvent {
  event_type = 'viewVoiceCommands';
}

export class VoiceCommandTutorial implements BaseEvent {
  event_type = 'voiceCommandTutorial';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * activateEmailSend
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/activateEmailSend)
   *
   * Fire this event when a user selects the checkbox to send emails invites to users.
   *
   * @param options Amplitude event options.
   */
  activateEmailSend(
    options?: EventOptions,
  ) {
    return this.track(new ActivateEmailSend(), options);
  }

  /**
   * activateListenOnly
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/activateListenOnly)
   *
   * Fire this event when a user activates "listen only" feature on a feed
   *
   * @param options Amplitude event options.
   */
  activateListenOnly(
    options?: EventOptions,
  ) {
    return this.track(new ActivateListenOnly(), options);
  }

  /**
   * addNewUsers
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/addNewUsers)
   *
   * when the button is clicked to open the invite modal
   *
   * @param properties The event's properties (e.g. feedId)
   * @param options Amplitude event options.
   */
  addNewUsers(
    properties?: AddNewUsersProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddNewUsers(properties), options);
  }

  /**
   * AddWorkspaceUser
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/AddWorkspaceUser)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  addWorkspaceUser(
    options?: EventOptions,
  ) {
    return this.track(new AddWorkspaceUser(), options);
  }

  /**
   * adminDeleteRecord
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/adminDeleteRecord)
   *
   * Fire event when an admin deletes the recording of another user.
   *
   * @param options Amplitude event options.
   */
  adminDeleteRecord(
    options?: EventOptions,
  ) {
    return this.track(new AdminDeleteRecord(), options);
  }

  /**
   * applicationBootTime
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/applicationBootTime)
   *
   * Lists out how long the application takes to boot. Multiples items can be tracked.
   *
   * @param properties The event's properties (e.g. endTime)
   * @param options Amplitude event options.
   */
  applicationBootTime(
    properties: ApplicationBootTimeProperties,
    options?: EventOptions,
  ) {
    return this.track(new ApplicationBootTime(properties), options);
  }

  /**
   * appPhoneCreateNewNumber
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/appPhoneCreateNewNumber)
   *
   * 1. Trigger this event when a user creates a new app phone number
   *
   * @param options Amplitude event options.
   */
  appPhoneCreateNewNumber(
    options?: EventOptions,
  ) {
    return this.track(new AppPhoneCreateNewNumber(), options);
  }

  /**
   * appPhoneLinkCopied
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/appPhoneLinkCopied)
   *
   * 1. Trigger this event when a user copies the link to their app phone number
   *
   * @param options Amplitude event options.
   */
  appPhoneLinkCopied(
    options?: EventOptions,
  ) {
    return this.track(new AppPhoneLinkCopied(), options);
  }

  /**
   * appPhoneStart
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/appPhoneStart)
   *
   * Trigger this event when a user clicks Start a phone call
   *
   * @param options Amplitude event options.
   */
  appPhoneStart(
    options?: EventOptions,
  ) {
    return this.track(new AppPhoneStart(), options);
  }

  /**
   * audioFileUpload
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/audioFileUpload)
   *
   * Fire this event when the user initiates the upload process. So once they select the file and it starts uploading, that's when the event should fire.
   *
   * @param options Amplitude event options.
   */
  audioFileUpload(
    options?: EventOptions,
  ) {
    return this.track(new AudioFileUpload(), options);
  }

  /**
   * audioProcessingTimeout
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/audioProcessingTimeout)
   *
   * This event is triggered when the audio processing exceeds the specified timeout limit.
   *
   * @param options Amplitude event options.
   */
  audioProcessingTimeout(
    options?: EventOptions,
  ) {
    return this.track(new AudioProcessingTimeout(), options);
  }

  /**
   * authScreen
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/authScreen)
   *
   * When the user sees any authentication screen. I know it's a different domain, but can we catch the event when the user is thrown to the auth screen?
   *
   * Owner: Nick DePrey
   *
   * @param properties The event's properties (e.g. Auth0 App)
   * @param options Amplitude event options.
   */
  authScreen(
    properties: AuthScreenProperties,
    options?: EventOptions,
  ) {
    return this.track(new AuthScreen(properties), options);
  }

  /**
   * authSuccess
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/authSuccess)
   *
   * Fire this event when the user successfully passes through the auth0 flow and receives a token
   *
   * Owner: Nick DePrey
   *
   * @param properties The event's properties (e.g. Auth0 App)
   * @param options Amplitude event options.
   */
  authSuccess(
    properties: AuthSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new AuthSuccess(properties), options);
  }

  /**
   * autoplayRecord
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/autoplayRecord)
   *
   * Fire this event when a next message is automatically played in a feed
   *
   * @param properties The event's properties (e.g. feedId)
   * @param options Amplitude event options.
   */
  autoplayRecord(
    properties?: AutoplayRecordProperties,
    options?: EventOptions,
  ) {
    return this.track(new AutoplayRecord(properties), options);
  }

  /**
   * bluetoothRecordTrigger
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/bluetoothRecordTrigger)
   *
   * Fire this event when a recording is initiated via bluetooth
   *
   * @param options Amplitude event options.
   */
  bluetoothRecordTrigger(
    options?: EventOptions,
  ) {
    return this.track(new BluetoothRecordTrigger(), options);
  }

  /**
   * BrowserCantGetToken
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/BrowserCantGetToken)
   *
   * A modal appears when the browser if having trouble getting a firebase token
   *
   * @param options Amplitude event options.
   */
  browserCantGetToken(
    options?: EventOptions,
  ) {
    return this.track(new BrowserCantGetToken(), options);
  }

  /**
   * completeOnboardingDone
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/completeOnboardingDone)
   *
   * Fired when user presses **Done** to complete step 3 in onboarding
   *
   * Owner: Nick DePrey
   *
   * @param options Amplitude event options.
   */
  completeOnboardingDone(
    options?: EventOptions,
  ) {
    return this.track(new CompleteOnboardingDone(), options);
  }

  /**
   * completeOnboardingSkip
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/completeOnboardingSkip)
   *
   * Fired when your presses **Skip for now** to complete step 3 from onboarding
   *
   * Owner: Nick DePrey
   *
   * @param options Amplitude event options.
   */
  completeOnboardingSkip(
    options?: EventOptions,
  ) {
    return this.track(new CompleteOnboardingSkip(), options);
  }

  /**
   * copyFeedLink
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/copyFeedLink)
   *
   * This captures the general share intent of opening the share dialogue on mobile apps or copying the feed link from web apps
   *
   * Owner: kurt@storyboard.fm
   *
   * @param properties The event's properties (e.g. feedId)
   * @param options Amplitude event options.
   */
  copyFeedLink(
    properties?: CopyFeedLinkProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyFeedLink(properties), options);
  }

  /**
   * createFirstFeed
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/createFirstFeed)
   *
   * Fires when user creates their first feed from the onboarding screen by pressing **Continue**
   *
   * Owner: Nick DePrey
   *
   * @param properties The event's properties (e.g. listenOnly)
   * @param options Amplitude event options.
   */
  createFirstFeed(
    properties?: CreateFirstFeedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateFirstFeed(properties), options);
  }

  /**
   * createFleetMessage
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/createFleetMessage)
   *
   * Fire this event when a user types in their Fleet Message then continues on to the next step of choosing channels
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  createFleetMessage(
    properties?: CreateFleetMessageProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateFleetMessage(properties), options);
  }

  /**
   * createFleetMessageFromDriverMessage
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/createFleetMessageFromDriverMessage)
   *
   * when you convert a driver meessage into a fleet message
   *
   * @param options Amplitude event options.
   */
  createFleetMessageFromDriverMessage(
    options?: EventOptions,
  ) {
    return this.track(new CreateFleetMessageFromDriverMessage(), options);
  }

  /**
   * createWorkspace
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/createWorkspace)
   *
   * Fire this event when a user creates a new workspace. A new workspace can be created from an existing workspace or through the OB flow for a new user.
   *
   * @param options Amplitude event options.
   */
  createWorkspace(
    options?: EventOptions,
  ) {
    return this.track(new CreateWorkspace(), options);
  }

  /**
   * deactivateEmailSend
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/deactivateEmailSend)
   *
   * Fire this event when a user unselects the checkbox that prevents emails from sending.
   *
   * @param options Amplitude event options.
   */
  deactivateEmailSend(
    options?: EventOptions,
  ) {
    return this.track(new DeactivateEmailSend(), options);
  }

  /**
   * deleteAccount
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/deleteAccount)
   *
   * Trigger this event when a user deletes their account in settings.
   *
   * @param options Amplitude event options.
   */
  deleteAccount(
    options?: EventOptions,
  ) {
    return this.track(new DeleteAccount(), options);
  }

  /**
   * deleteRecord
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/deleteRecord)
   *
   * Fire this event when a recording is deleted
   *
   * Owner: kurt@storyboard.fm
   *
   * @param properties The event's properties (e.g. itemId)
   * @param options Amplitude event options.
   */
  deleteRecord(
    properties?: DeleteRecordProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeleteRecord(properties), options);
  }

  /**
   * deleteToken
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/deleteToken)
   *
   * User deletes their push notification token
   *
   * @param properties The event's properties (e.g. token)
   * @param options Amplitude event options.
   */
  deleteToken(
    properties: DeleteTokenProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeleteToken(properties), options);
  }

  /**
   * desktopPushNotificationReceived
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/desktopPushNotificationReceived)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. payload)
   * @param options Amplitude event options.
   */
  desktopPushNotificationReceived(
    properties?: DesktopPushNotificationReceivedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DesktopPushNotificationReceived(properties), options);
  }

  /**
   * dispatchCallAnswered
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/dispatchCallAnswered)
   *
   * Fire this event when a dispatcher places a call and the call is answered by the driver
   *
   * @param options Amplitude event options.
   */
  dispatchCallAnswered(
    options?: EventOptions,
  ) {
    return this.track(new DispatchCallAnswered(), options);
  }

  /**
   * dispatchCallFailed
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/dispatchCallFailed)
   *
   * Fire this event when a dispatcher places a call that fails to connect and ring the driver
   *
   * @param options Amplitude event options.
   */
  dispatchCallFailed(
    options?: EventOptions,
  ) {
    return this.track(new DispatchCallFailed(), options);
  }

  /**
   * dispatchCallPlaced
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/dispatchCallPlaced)
   *
   * Fire this event when a dispatcher clicks the phone button in a feed.
   *
   * @param options Amplitude event options.
   */
  dispatchCallPlaced(
    options?: EventOptions,
  ) {
    return this.track(new DispatchCallPlaced(), options);
  }

  /**
   * dispatchCallRinging
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/dispatchCallRinging)
   *
   * Fire this event when the call begins ringing for the dispatcher
   *
   * @param options Amplitude event options.
   */
  dispatchCallRinging(
    options?: EventOptions,
  ) {
    return this.track(new DispatchCallRinging(), options);
  }

  /**
   * dispatchCallTimeout
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/dispatchCallTimeout)
   *
   * Fire this event when a dispatcher places a call that is not answered
   *
   * @param options Amplitude event options.
   */
  dispatchCallTimeout(
    options?: EventOptions,
  ) {
    return this.track(new DispatchCallTimeout(), options);
  }

  /**
   * downgradePermissionMember
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/downgradePermissionMember)
   *
   * Fire this event when a user is upgraded from workspace admin permissions to workspace member permissions
   *
   * @param options Amplitude event options.
   */
  downgradePermissionMember(
    options?: EventOptions,
  ) {
    return this.track(new DowngradePermissionMember(), options);
  }

  /**
   * editWorkspaceName
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/editWorkspaceName)
   *
   * Fire this event when a user selects Edit "Workspace name"
   *
   * @param options Amplitude event options.
   */
  editWorkspaceName(
    options?: EventOptions,
  ) {
    return this.track(new EditWorkspaceName(), options);
  }

  /**
   * failedToRetrieveToken
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/failedToRetrieveToken)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. error)
   * @param options Amplitude event options.
   */
  failedToRetrieveToken(
    properties?: FailedToRetrieveTokenProperties,
    options?: EventOptions,
  ) {
    return this.track(new FailedToRetrieveToken(properties), options);
  }

  /**
   * feedback
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/feedback)
   *
   * Fire this event when a user selects the feedback icon
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  feedback(
    properties?: FeedbackProperties,
    options?: EventOptions,
  ) {
    return this.track(new Feedback(properties), options);
  }

  /**
   * feedCreate
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/feedCreate)
   *
   * Fire this event when a new channel is created
   *
   * Owner: Nick DePrey
   *
   * @param properties The event's properties (e.g. feedId)
   * @param options Amplitude event options.
   */
  feedCreate(
    properties?: FeedCreateProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeedCreate(properties), options);
  }

  /**
   * feedOpen
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/feedOpen)
   *
   * Fire this event when a feed is opened
   *
   * Owner: Nick DePrey
   *
   * @param properties The event's properties (e.g. feedId)
   * @param options Amplitude event options.
   */
  feedOpen(
    properties?: FeedOpenProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeedOpen(properties), options);
  }

  /**
   * feedsListOpen
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/feedsListOpen)
   *
   * Fire event when user opens members drawer from a feed
   *
   * @param options Amplitude event options.
   */
  feedsListOpen(
    options?: EventOptions,
  ) {
    return this.track(new FeedsListOpen(), options);
  }

  /**
   * fileUploadChooseFile
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/fileUploadChooseFile)
   *
   * User selects choose file button
   *
   * @param options Amplitude event options.
   */
  fileUploadChooseFile(
    options?: EventOptions,
  ) {
    return this.track(new FileUploadChooseFile(), options);
  }

  /**
   * fileUploadError
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/fileUploadError)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  fileUploadError(
    options?: EventOptions,
  ) {
    return this.track(new FileUploadError(), options);
  }

  /**
   * fileUploadInvoke
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/fileUploadInvoke)
   *
   * User selects file upload button
   *
   * @param options Amplitude event options.
   */
  fileUploadInvoke(
    options?: EventOptions,
  ) {
    return this.track(new FileUploadInvoke(), options);
  }

  /**
   * fileUploadPhotoGallery
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/fileUploadPhotoGallery)
   *
   * User selects photo by selecting photo gallery button.
   *
   * @param options Amplitude event options.
   */
  fileUploadPhotoGallery(
    options?: EventOptions,
  ) {
    return this.track(new FileUploadPhotoGallery(), options);
  }

  /**
   * fileUploadSubmit
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/fileUploadSubmit)
   *
   * This event is triggered when a user submits a file upload request.
   *
   * @param options Amplitude event options.
   */
  fileUploadSubmit(
    options?: EventOptions,
  ) {
    return this.track(new FileUploadSubmit(), options);
  }

  /**
   * groupCollapse
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/groupCollapse)
   *
   * Fire this event when a user collapses a group
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  groupCollapse(
    properties?: GroupCollapseProperties,
    options?: EventOptions,
  ) {
    return this.track(new GroupCollapse(properties), options);
  }

  /**
   * groupExpand
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/groupExpand)
   *
   * Fire this event when a user expands a group in the channel list
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  groupExpand(
    properties?: GroupExpandProperties,
    options?: EventOptions,
  ) {
    return this.track(new GroupExpand(properties), options);
  }

  /**
   * groupJoin
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/groupJoin)
   *
   * Fire this event when a user joins all channels in a group
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  groupJoin(
    properties?: GroupJoinProperties,
    options?: EventOptions,
  ) {
    return this.track(new GroupJoin(properties), options);
  }

  /**
   * groupLeave
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/groupLeave)
   *
   * Fire this event when a user leaves all channels in a group
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  groupLeave(
    properties?: GroupLeaveProperties,
    options?: EventOptions,
  ) {
    return this.track(new GroupLeave(properties), options);
  }

  /**
   * groupMarkAllRead
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/groupMarkAllRead)
   *
   * Fire this event when a user right clicks a group and marks all channels as read
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  groupMarkAllRead(
    properties?: GroupMarkAllReadProperties,
    options?: EventOptions,
  ) {
    return this.track(new GroupMarkAllRead(properties), options);
  }

  /**
   * hasToken
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/hasToken)
   *
   * user has a push notification token
   *
   * @param properties The event's properties (e.g. token)
   * @param options Amplitude event options.
   */
  hasToken(
    properties: HasTokenProperties,
    options?: EventOptions,
  ) {
    return this.track(new HasToken(properties), options);
  }

  /**
   * inviteWorkspaceUser
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/inviteWorkspaceUser)
   *
   * Fire this event new users are invited to the workspace.
   *
   * @param options Amplitude event options.
   */
  inviteWorkspaceUser(
    options?: EventOptions,
  ) {
    return this.track(new InviteWorkspaceUser(), options);
  }

  /**
   * linkUploadError
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/linkUploadError)
   *
   * Link upload error
   *
   * @param options Amplitude event options.
   */
  linkUploadError(
    options?: EventOptions,
  ) {
    return this.track(new LinkUploadError(), options);
  }

  /**
   * linkUploadInvoke
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/linkUploadInvoke)
   *
   * User selects the link button
   *
   * @param options Amplitude event options.
   */
  linkUploadInvoke(
    options?: EventOptions,
  ) {
    return this.track(new LinkUploadInvoke(), options);
  }

  /**
   * linkUploadSubmit
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/linkUploadSubmit)
   *
   * User selects the link submit button
   *
   * @param options Amplitude event options.
   */
  linkUploadSubmit(
    options?: EventOptions,
  ) {
    return this.track(new LinkUploadSubmit(), options);
  }

  /**
   * localProcessingError
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/localProcessingError)
   *
   * This event tracks errors that occur during local processing.
   *
   * @param properties The event's properties (e.g. duration)
   * @param options Amplitude event options.
   */
  localProcessingError(
    properties: LocalProcessingErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new LocalProcessingError(properties), options);
  }

  /**
   * loginViaLink
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/loginViaLink)
   *
   * Fire this event when a user logs
   *
   * @param options Amplitude event options.
   */
  loginViaLink(
    options?: EventOptions,
  ) {
    return this.track(new LoginViaLink(), options);
  }

  /**
   * logoutManually
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/logoutManually)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  logoutManually(
    options?: EventOptions,
  ) {
    return this.track(new LogoutManually(), options);
  }

  /**
   * logoutViaLink
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/logoutViaLink)
   *
   * Fire this event when a user logs out using a link
   *
   * @param options Amplitude event options.
   */
  logoutViaLink(
    options?: EventOptions,
  ) {
    return this.track(new LogoutViaLink(), options);
  }

  /**
   * manageUsers
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/manageUsers)
   *
   * Fire this event when a user selects "manage users"
   *
   * @param options Amplitude event options.
   */
  manageUsers(
    options?: EventOptions,
  ) {
    return this.track(new ManageUsers(), options);
  }

  /**
   * membersListOpen
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/membersListOpen)
   *
   * Fire event when user opens the members panel from within a feed
   *
   * @param options Amplitude event options.
   */
  membersListOpen(
    options?: EventOptions,
  ) {
    return this.track(new MembersListOpen(), options);
  }

  /**
   * noWorkspaceView
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/noWorkspaceView)
   *
   * Fire this event when a user sees the no workspaces screen
   *
   * @param options Amplitude event options.
   */
  noWorkspaceView(
    options?: EventOptions,
  ) {
    return this.track(new NoWorkspaceView(), options);
  }

  /**
   * noWorkspaceViewLogout
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/noWorkspaceViewLogout)
   *
   * Fire this event when a user sees the no workspaces screen and uses that logout link
   *
   * @param options Amplitude event options.
   */
  noWorkspaceViewLogout(
    options?: EventOptions,
  ) {
    return this.track(new NoWorkspaceViewLogout(), options);
  }

  /**
   * openFile
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/openFile)
   *
   * User selects the download file button.
   *
   * @param options Amplitude event options.
   */
  openFile(
    options?: EventOptions,
  ) {
    return this.track(new OpenFile(), options);
  }

  /**
   * openFleetMessage
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/openFleetMessage)
   *
   * Fire this event when a user clicks Fleet Messages to open the Fleet Messages text box
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  openFleetMessage(
    properties?: OpenFleetMessageProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenFleetMessage(properties), options);
  }

  /**
   * openLink
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/openLink)
   *
   * User selects link
   *
   * @param options Amplitude event options.
   */
  openLink(
    options?: EventOptions,
  ) {
    return this.track(new OpenLink(), options);
  }

  /**
   * openTranscript
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/openTranscript)
   *
   * Fire this event when a user expands a transcript
   *
   * @param properties The event's properties (e.g. feedId)
   * @param options Amplitude event options.
   */
  openTranscript(
    properties?: OpenTranscriptProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenTranscript(properties), options);
  }

  /**
   * outboundCallAnswered
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/outboundCallAnswered)
   *
   * 1. Trigger this event when an outbound call is answered by the receiving party
   *
   * @param options Amplitude event options.
   */
  outboundCallAnswered(
    options?: EventOptions,
  ) {
    return this.track(new OutboundCallAnswered(), options);
  }

  /**
   * outboundCallEnded
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/outboundCallEnded)
   *
   * 1. When you hang up or the other person hangs up
   *
   * @param options Amplitude event options.
   */
  outboundCallEnded(
    options?: EventOptions,
  ) {
    return this.track(new OutboundCallEnded(), options);
  }

  /**
   * outboundCallFail
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/outboundCallFail)
   *
   * 1. Trigger this event when an outbound call fails
   *
   * @param options Amplitude event options.
   */
  outboundCallFail(
    options?: EventOptions,
  ) {
    return this.track(new OutboundCallFail(), options);
  }

  /**
   * outboundCallPlaced
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/outboundCallPlaced)
   *
   * 1. Trigger this event when uses the dialer and places a call
   *
   * @param options Amplitude event options.
   */
  outboundCallPlaced(
    options?: EventOptions,
  ) {
    return this.track(new OutboundCallPlaced(), options);
  }

  /**
   * playRecord
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/playRecord)
   *
   * Fire this event when a user clicks the play button on an audio message.
   *
   * @param properties The event's properties (e.g. feedId)
   * @param options Amplitude event options.
   */
  playRecord(
    properties?: PlayRecordProperties,
    options?: EventOptions,
  ) {
    return this.track(new PlayRecord(properties), options);
  }

  /**
   * pushNotification
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/pushNotification)
   *
   * web receives a push notification
   *
   * @param properties The event's properties (e.g. payload)
   * @param options Amplitude event options.
   */
  pushNotification(
    properties?: PushNotificationProperties,
    options?: EventOptions,
  ) {
    return this.track(new PushNotification(properties), options);
  }

  /**
   * RemoveWorkspaceAccess
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/RemoveWorkspaceAccess)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  removeWorkspaceAccess(
    options?: EventOptions,
  ) {
    return this.track(new RemoveWorkspaceAccess(), options);
  }

  /**
   * revokePendingInvite
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/revokePendingInvite)
   *
   * Fire this event when a pending invite to a workspace is revoked.
   *
   * @param options Amplitude event options.
   */
  revokePendingInvite(
    options?: EventOptions,
  ) {
    return this.track(new RevokePendingInvite(), options);
  }

  /**
   * searchClick
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/searchClick)
   *
   * Fire this event when a user opens the search box
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  searchClick(
    properties?: SearchClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchClick(properties), options);
  }

  /**
   * searchExecute
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/searchExecute)
   *
   * Fire this event when a search query is executed
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  searchExecute(
    properties?: SearchExecuteProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchExecute(properties), options);
  }

  /**
   * searchLinkToMessage
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/searchLinkToMessage)
   *
   * Fire this event when a user clicks a search result to link to that message or channel
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  searchLinkToMessage(
    properties?: SearchLinkToMessageProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchLinkToMessage(properties), options);
  }

  /**
   * sendFeedback
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/sendFeedback)
   *
   * Fire this event when a user submits feedback using the feedback button
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  sendFeedback(
    properties?: SendFeedbackProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendFeedback(properties), options);
  }

  /**
   * sendFleetMessage
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/sendFleetMessage)
   *
   * Fire this event when a user sends a fleet message
   *
   * @param options Amplitude event options.
   */
  sendFleetMessage(
    options?: EventOptions,
  ) {
    return this.track(new SendFleetMessage(), options);
  }

  /**
   * sendGeoFleetMessage
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/sendGeoFleetMessage)
   *
   * when you send a fleet message from the geo fleet message tab
   *
   * @param options Amplitude event options.
   */
  sendGeoFleetMessage(
    options?: EventOptions,
  ) {
    return this.track(new SendGeoFleetMessage(), options);
  }

  /**
   * Serviceworker of Notification not available
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/Serviceworker%20of%20Notification%20not%20available)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  serviceworkerOfNotificationNotAvailable(
    options?: EventOptions,
  ) {
    return this.track(new ServiceworkerOfNotificationNotAvailable(), options);
  }

  /**
   * startRecord
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/startRecord)
   *
   * Trigger this event when a recording is started
   *
   * Owner: Nick DePrey
   *
   * @param properties The event's properties (e.g. feedId)
   * @param options Amplitude event options.
   */
  startRecord(
    properties?: StartRecordProperties,
    options?: EventOptions,
  ) {
    return this.track(new StartRecord(properties), options);
  }

  /**
   * submitEmailInvite
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/submitEmailInvite)
   *
   * Event is triggered when the user submits by selecting the INVITE VIA EMAIL button.
   *
   * @param properties The event's properties (e.g. feedId)
   * @param options Amplitude event options.
   */
  submitEmailInvite(
    properties?: SubmitEmailInviteProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitEmailInvite(properties), options);
  }

  /**
   * submitFullName
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/submitFullName)
   *
   * When user submits their full name in the onboarding screen by pressing the **Continue** button
   *
   * Owner: Nick DePrey
   *
   * @param options Amplitude event options.
   */
  submitFullName(
    options?: EventOptions,
  ) {
    return this.track(new SubmitFullName(), options);
  }

  /**
   * textToSpeechMessageSend
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/textToSpeechMessageSend)
   *
   * Fire this event when a user sends a text-to-speech message from web.
   *
   * @param options Amplitude event options.
   */
  textToSpeechMessageSend(
    options?: EventOptions,
  ) {
    return this.track(new TextToSpeechMessageSend(), options);
  }

  /**
   * toggleHandsFreeOff
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/toggleHandsFreeOff)
   *
   * Trigger this event when hands free mode is turned off
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  toggleHandsFreeOff(
    properties?: ToggleHandsFreeOffProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToggleHandsFreeOff(properties), options);
  }

  /**
   * toggleHandsFreeOn
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/toggleHandsFreeOn)
   *
   * Trigger this event when hands free mode is turned on
   *
   * @param properties The event's properties (e.g. workspaceId)
   * @param options Amplitude event options.
   */
  toggleHandsFreeOn(
    properties?: ToggleHandsFreeOnProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToggleHandsFreeOn(properties), options);
  }

  /**
   * updateUserLanguage
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/updateUserLanguage)
   *
   * Fire this event when a user changes their language perference in user settings
   *
   * @param properties The event's properties (e.g. translationLanguage)
   * @param options Amplitude event options.
   */
  updateUserLanguage(
    properties: UpdateUserLanguageProperties,
    options?: EventOptions,
  ) {
    return this.track(new UpdateUserLanguage(properties), options);
  }

  /**
   * upgradePermissionAdmin
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/upgradePermissionAdmin)
   *
   * Fire this event when a user is upgraded from workspace member permissions to workspace admin permissions
   *
   * @param options Amplitude event options.
   */
  upgradePermissionAdmin(
    options?: EventOptions,
  ) {
    return this.track(new UpgradePermissionAdmin(), options);
  }

  /**
   * uploadFailed
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/uploadFailed)
   *
   * This event is triggered when a user attempts to upload a file but encounters a failure in the process.
   *
   * @param properties The event's properties (e.g. feedId)
   * @param options Amplitude event options.
   */
  uploadFailed(
    properties?: UploadFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UploadFailed(properties), options);
  }

  /**
   * uploadRecord
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/uploadRecord)
   *
   * Trigger this event when a recording is uploaded
   *
   * Owner: Nick DePrey
   *
   * @param options Amplitude event options.
   */
  uploadRecord(
    options?: EventOptions,
  ) {
    return this.track(new UploadRecord(), options);
  }

  /**
   * uploadTimeout
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/uploadTimeout)
   *
   * Fire this event if the upload times out. This event tracks when a user's upload process exceeds the allowed time limit.
   *
   * @param options Amplitude event options.
   */
  uploadTimeout(
    options?: EventOptions,
  ) {
    return this.track(new UploadTimeout(), options);
  }

  /**
   * viewMessageContextMenu
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/viewMessageContextMenu)
   *
   * Fire event when user taps and holds a message to show the context menu (delete and message details button menu)
   *
   * @param options Amplitude event options.
   */
  viewMessageContextMenu(
    options?: EventOptions,
  ) {
    return this.track(new ViewMessageContextMenu(), options);
  }

  /**
   * viewMessageDetails
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/viewMessageDetails)
   *
   * Fire this event any time a user accessess the message details screen
   *
   * @param options Amplitude event options.
   */
  viewMessageDetails(
    options?: EventOptions,
  ) {
    return this.track(new ViewMessageDetails(), options);
  }

  /**
   * viewVoiceCommands
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/viewVoiceCommands)
   *
   * Fire this event when a user in a workspace that has HF access selects to view the voice commands
   *
   * @param options Amplitude event options.
   */
  viewVoiceCommands(
    options?: EventOptions,
  ) {
    return this.track(new ViewVoiceCommands(), options);
  }

  /**
   * voiceCommandTutorial
   *
   * [View in Tracking Plan](https://data.amplitude.com/storyboard/Storyboard/events/main/latest/voiceCommandTutorial)
   *
   * Fire this event when a user chooses to go through the voice commands tutorial.
   *
   * @param options Amplitude event options.
   */
  voiceCommandTutorial(
    options?: EventOptions,
  ) {
    return this.track(new VoiceCommandTutorial(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;

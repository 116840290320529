import { FullInput } from "@/components/Utils";
import Locator from "@/locator";
import { Box, useTheme } from "@mui/material";
import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";

const API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY ?? null;

export default function GooglePlacesAutocomplete({
  location,
  resetForm,
  setLocationValue,
  autoFocusLocation,
}: {
  location: string | null;
  resetForm: boolean;
  setLocationValue: (value: any) => void;
  autoFocusLocation?: boolean;
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [disableSearch, setDisableSearch] = useState<boolean>(false);
  const theme = useTheme();

  useEffect(() => {
    if (resetForm) {
      setSearchTerm("");
      setResults([]);
      setActiveIndex(-1);
    }
  }, [resetForm]);

  useEffect(() => {
    if (location) {
      setSearchTerm(() => location);
      setDisableSearch(() => true);
      setTimeout(() => {
        setDisableSearch(() => false);
      }, 1000);
    }
  }, [location]);

  const handleChange = (e) => {
    const value = e?.target?.value;
    setSearchTerm(value);
  };

  const fetchPlace = async (index: number) => {
    const activeResult = results[index];
    const placeId = activeResult?.placePrediction?.placeId;
    if (!placeId) {
      return;
    }

    const url = `https://places.googleapis.com/v1/places/${placeId}`;
    const headers = {
      "X-Goog-Api-Key": API_KEY,
      "X-Goog-FieldMask": "location",
    };
    try {
      const response = await fetch(url, {
        method: "GET",
        headers,
      });

      const json = await response.json();

      const combinedData = {
        ...activeResult,
        ...json,
      };

      setLocationValue(combinedData);
    } catch (error) {
      console.error(error.message);
    }
  };

  const autoCompleteLocation = async (value: string) => {
    if (disableSearch) {
      return;
    }
    const url = "https://places.googleapis.com/v1/places:autocomplete";
    const headers = {
      "X-Goog-Api-Key": API_KEY,
      "Content-Type": "application/json",
    };
    const body = {
      input: value,
      includedRegionCodes: ["US", "CA", "MX"],
      includedPrimaryTypes: ["locality", "postal_code", "administrative_area_level_1"],
    };
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers,
      });

      const json = await response.json();
      return json?.suggestions;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Fetch the location data from the placeId
   */
  useEffect(() => {
    if (debouncedSearchTerm?.length === 0) {
      setResults(() => []);
      setActiveIndex(() => -1);
    }
    if (debouncedSearchTerm?.length >= 2) {
      autoCompleteLocation(debouncedSearchTerm)
        .then((data) => {
          setResults(() => data);
        })
        .catch((error) => console.error({ error }));
    }
  }, [debouncedSearchTerm]);

  const keyDownHandler = (event: KeyboardEvent) => {
    if (!searchTerm || searchTerm?.length === 0) {
      return;
    }
    const { code } = event;
    if (code === "ArrowDown") {
      const lastItem = results?.length - 1;
      if (activeIndex === lastItem) {
        return;
      }
      setActiveIndex((value) => value + 1);
    }
    if (code === "ArrowUp") {
      if (activeIndex === -1) {
        return;
      }
      setActiveIndex((value) => value - 1);
    }
    if (code === "Enter") {
      if (activeIndex >= 0) {
        fetchPlace(activeIndex);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", keyDownHandler);
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  });

  if (!API_KEY) {
    return <Box sx={{ background: "red", textAlign: "center", p: 2 }}> Missing API Key</Box>;
  }

  return (
    <Box sx={{ position: "relative" }}>
      <FullInput
        required={true}
        label={Locator.feed.input.fleetMessage.modal.location}
        value={searchTerm}
        callback={(e) => handleChange(e)}
        placeholder="Start typing a location name"
        aria-label={Locator.feed.input.fleetMessage.modal.location}
        sx={{ m: 0 }}
        autoFocus={autoFocusLocation}
      />

      {results?.length > 0 && (
        <Box sx={{ position: "absolute", zIndex: 100, width: "100%" }}>
          <Box
            component="ul"
            sx={{
              background: theme.palette.secondary.main,
              mt: 1,
              mb: 1,
              p: 0.5,
              borderRadius: 2,
              border: `solid 1px ${theme.palette.secondary.light}`,
            }}
          >
            {results.map((result, index) => (
              <Box
                component="li"
                key={result.placePrediction.placeId}
                sx={{
                  cursor: "pointer",
                  listStyleType: "none",
                  mb: index !== results.length - 1 ? 1 : 0,
                  py: 0.5,
                  px: 1,
                  borderRadius: 2,
                  background: index === activeIndex ? theme.palette.secondary.dark : "",
                  "&:hover": {
                    background: theme.palette.secondary.dark,
                    boxShadow: `0 0 0 1px ${theme.palette.secondary.light}`,
                  },
                }}
                onClick={() => {
                  setActiveIndex(() => index);
                  fetchPlace(index);
                }}
              >
                <Box>
                  <Box sx={{ fontSize: "14px" }}>{result.placePrediction.structuredFormat.mainText.text}</Box>
                  <Box sx={{ color: "#ccc", fontSize: "12px" }}>{result.placePrediction.text.text}</Box>
                </Box>
              </Box>
            ))}
          </Box>
          <Box sx={{ textAlign: "right" }}>
            <img src="https://developers.google.com/static/maps/documentation/images/google_on_non_white.png" />
          </Box>
        </Box>
      )}
    </Box>
  );
}

import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import type React from "react";

type MenuItemType = {
  text: string;
  value: string;
  active: boolean;
  icon?: React.ReactElement;
};
interface ButtonMenuProps {
  activeValue: string;
  changeValue: (value: string) => void;
  values: MenuItemType[];
  title: string;
  indicateActive?: boolean;
  icon?: React.ReactElement;
}

export default function ButtonDropdownMenu({
  changeValue,
  activeValue,
  values,
  title,
  indicateActive,
  icon,
}: ButtonMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems: MenuItemType[] = values.map((menuItem: MenuItemType) => ({
    ...menuItem,
    active: menuItem.value === activeValue,
  }));

  return (
    <>
      <Tooltip title={title}>
        <Box
          component="button"
          type="button"
          onClick={handleClick}
          aria-label={title}
          aria-controls={open ? "dropdown-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          className={`dropdown-menu-trigger ${activeValue && indicateActive ? "active" : ""}`}
        >
          {icon}
        </Box>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        className="button-dropdown-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Box className="button-dropdown">
          <Box className="button-dropdown-title">{title}</Box>
          {menuItems.map((filter, index) => (
            <Box key={filter.value} className={`button-dropdown-item ${filter.active ? "active" : ""}`}>
              <button
                type="button"
                className="button-dropdown-button space-x-0.5"
                onClick={() => {
                  changeValue(filter.value);
                  handleClose();
                }}
              >
                {filter?.icon && <Box className="button-dropdown-icon">{filter.icon}</Box>}
                <Box>{filter.text}</Box>
              </button>
            </Box>
          ))}
        </Box>
      </Menu>
    </>
  );
}

import AvatarWithStatus from "@/components/AvatarWithStatus";
import UseContextMenu from "@/components/ContextMenus/UseContextMenu";
import LinkContextMenu from "@/components/WorkspaceChannelList/LinkContextMenu";
import Locator from "@/locator";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import { TelemetryContext, actions } from "@/models/TelemetryProvider";
import { ChannelList } from "@/models/commonQueries";
import LockIcon from "@mui/icons-material/Lock";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Box, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

interface LinkProps {
  channel: ChannelList;
  active: boolean;
  readOnlyMode: boolean;
  workspaceMembershipId: string;
  limitedMember: boolean;
}

export default function WorkspaceChannelLinkItem({
  channel,
  active,
  readOnlyMode,
  workspaceMembershipId,
  limitedMember,
}: LinkProps) {
  const { handleContextMenu, handleClose, contextMenuItem, contextMenu } =
    UseContextMenu();
  const { trackAction } = useContext(TelemetryContext);
  const { currentFeedId: feedId } = useContext(CurrentFeedContext);
  const navigate = useNavigate();
  const channelUrl = `/workspaces/${channel.workspaceId}/feeds/${channel.id}`;
  const [disabled, setDisabled] = useState<boolean>(false);

  const joinChannel = async (id: string, redirect: boolean) => {
    setDisabled(() => true);
    handleClose();
    setDisabled(() => false);
    if (feedId !== id && redirect) {
      navigate(channelUrl);
    }
  };

  const aliasOwnerAccountId =
    channel.aliasChannel &&
    !channel.hasMultipleAlias &&
    channel.aliasOwnerAccountId;

  const debug = false;

  return (
    <>
      <Link
        data-testid={Locator.workspaceNav.channels.list.testId(channel.id)}
        aria-label={Locator.workspaceNav.channels.list.item}
        to={`${disabled ? "#" : channelUrl}`}
        onContextMenu={!limitedMember && handleContextMenu}
        className={`
        workspace-channel-item
        ${active ? "active" : ""}
        ${channel?.subscribed ? "subscribed" : "not-subscribed"}
        ${disabled ? "disabled" : ""}
      `}
        onClick={async (e) => {
          if (contextMenu) {
            e.preventDefault();
            return;
          }
          if (!channel?.subscribed) {
            e.preventDefault();
            await joinChannel(channel.id, true);
          }
          trackAction(actions.feedNavigate(channel.id));
        }}
      >
        <Box
          className={`
          workspace-channel-item-container
          ${channel?.groupId ? "has-group" : ""}
        `}
        >
          {aliasOwnerAccountId && (
            <AvatarWithStatus
              sx={{ mr: 1 }}
              size={30}
              accountId={aliasOwnerAccountId}
            />
          )}
          <Box className="workspace-channel-item-container-left ">
            {channel?.isPrivate && !channel?.aliasChannel && (
              <Box className="workspace-channel-item-lock">
                <LockIcon sx={{ fontSize: "1.125rem" }} />
              </Box>
            )}

            <Box
              sx={{ flexGrow: 1 }}
              className="truncate"
              aria-label={channel.title}
            >
              {channel.title}
            </Box>
          </Box>
          {channel?.groupId && (
            <Box
              className="workspace-channel-item-container-right truncate"
              aria-label={channel.groupName}
            >
              <Box className="group-name">{channel.groupName}</Box>
            </Box>
          )}
          {channel?.subscribed && (
            <Box className="workspace-channel-item-container-subscribed">
              <Tooltip title="Subscribed to notifications">
                <NotificationsActiveIcon
                  sx={{ fontSize: "18px", color: "#fff" }}
                />
              </Tooltip>
            </Box>
          )}
          <Box className="unread-bubble-container">
            <Box
              className={`
              unread-bubble
              ${channel.unread && channel.subscribed ? "unread" : ""}
              ${
                channel.unread && !channel.subscribed
                  ? "unread-not-subscribed"
                  : ""
              }
            `}
            />
          </Box>
        </Box>
        {contextMenuItem(
          <LinkContextMenu
            readOnlyMode={readOnlyMode}
            channel={channel}
            workspaceMembershipId={workspaceMembershipId}
            channelUrl={channelUrl}
            handleClose={handleClose}
          />,
        )}
      </Link>
      {debug && (
        <Box sx={{ background: "#333" }}>
          <Box component="pre" sx={{ fontSize: "12px" }}>
            {JSON.stringify(channel, null, 2)}
          </Box>
        </Box>
      )}
    </>
  );
}

import GooglePlacesAutocomplete from "@/components/GooglePlacesAutocomplete";
import { FullInput } from "@/components/Utils";
import { Box, Checkbox, Collapse, ListItemButton } from "@mui/material";
import { useContext, useEffect, useState } from "react";
const defaultRadiusMiles = 100;
import Locator from "@/locator";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { UxContext } from "@/models/UxStateProvider";
import { lookupWorkspaceMembershipsWithinRadius } from "@/models/geoLookup";
import theme from "@/theme/theme";
import { pluralValue } from "@/utils";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

// Validation constants
const LATITUDE_MIN = -90;
const LATITUDE_MAX = 90;
const LONGITUDE_MIN = -180;
const LONGITUDE_MAX = 180;

export default function GeoTargetRecipients({
  resetGeoValues,
  fleetMessageInvalidLocation,
}: { resetGeoValues: boolean; fleetMessageInvalidLocation?: boolean }) {
  const {
    setFleetMessageActiveChannels,
    fleetMessageActiveChannels,
    fleetMessageRadius,
    setFleetMessageRadius,
    setFleetMessageLocation,
    fleetMessageLocation,
    fleetMessageLatitude,
    setFleetMessageLatitude,
    fleetMessageLongitude,
    setFleetMessageLongitude,
  } = useContext(UxContext);

  const [resetForm, setResetForm] = useState<boolean>(false);
  const [resultsFound, setResultsFound] = useState<{ id: string; title: string }[] | null>(null);
  const [radiusError, setRadiusError] = useState<string | null>(null);
  const [latitudeError, setLatitudeError] = useState<string | null>(null);
  const [longitudeError, setLongitudeError] = useState<string | null>(null);
  const { currentWorkspaceId } = useContext(WorkspaceContext);

  // Generic validation function for numeric inputs
  const validateNumericInput = (
    value: string,
    setError: (error: string | null) => void,
    options?: {
      min?: number;
      max?: number;
      fieldName?: string;
    },
  ) => {
    const numValue = Number(value);
    const { min, max, fieldName = "Value" } = options || {};

    if (Number.isNaN(numValue)) {
      setError("Please enter a valid number");
      return null;
    }

    if (min !== undefined && numValue < min) {
      setError(`${fieldName} must be at least ${min}`);
      return null;
    }

    if (max !== undefined && numValue > max) {
      setError(`${fieldName} must be at most ${max}`);
      return null;
    }

    setError(null);
    return numValue;
  };

  const setLocalLocationAndReset = (value: any) => {
    let isValidLocation = true;

    if (value?.location?.latitude !== undefined) {
      const lat = value.location.latitude;
      if (lat < LATITUDE_MIN || lat > LATITUDE_MAX) {
        setLatitudeError(`Latitude must be between ${LATITUDE_MIN} and ${LATITUDE_MAX}`);
        isValidLocation = false;
      } else {
        setFleetMessageLatitude(lat);
        setLatitudeError(null);
      }
    }

    if (value?.location?.longitude !== undefined) {
      const lng = value.location.longitude;
      if (lng < LONGITUDE_MIN || lng > LONGITUDE_MAX) {
        setLongitudeError(`Longitude must be between ${LONGITUDE_MIN} and ${LONGITUDE_MAX}`);
        isValidLocation = false;
      } else {
        setFleetMessageLongitude(lng);
        setLongitudeError(null);
      }
    }

    setResetForm(() => true);
    setTimeout(() => {
      if (isValidLocation && value?.placePrediction?.text?.text) {
        setFleetMessageLocation(value.placePrediction.text.text);
      }
      setResetForm(() => false);
    }, 200);
  };

  useEffect(() => {
    const radiusNumber = validateNumericInput(fleetMessageRadius, setRadiusError, { min: 0, fieldName: "Radius" });
    const latitudeNumber = validateNumericInput(fleetMessageLatitude, setLatitudeError, {
      min: LATITUDE_MIN,
      max: LATITUDE_MAX,
      fieldName: "Latitude",
    });
    const longitudeNumber = validateNumericInput(fleetMessageLongitude, setLongitudeError, {
      min: LONGITUDE_MIN,
      max: LONGITUDE_MAX,
      fieldName: "Longitude",
    });

    if (
      latitudeNumber !== 0 &&
      longitudeNumber !== 0 &&
      latitudeNumber !== null &&
      longitudeNumber !== null &&
      radiusNumber > 0 &&
      latitudeNumber >= LATITUDE_MIN &&
      latitudeNumber <= LATITUDE_MAX &&
      longitudeNumber >= LONGITUDE_MIN &&
      longitudeNumber <= LONGITUDE_MAX
    ) {
      lookupWorkspaceMembershipsWithinRadius(latitudeNumber, longitudeNumber, radiusNumber, {
        workspaceId: currentWorkspaceId,
      })
        .then((data) => {
          setResultsFound(() => data);
          setFleetMessageActiveChannels(data?.map((feed) => feed.id) || []);
        })
        .catch((error) => console.error({ error }));
    } else {
      setResultsFound(() => null);
    }
  }, [fleetMessageLatitude, fleetMessageLongitude, fleetMessageRadius]);

  const resetValues = () => {
    setFleetMessageLocation("");
    setFleetMessageRadius(defaultRadiusMiles.toString());
    setFleetMessageLatitude("");
    setFleetMessageLongitude("");
    setResultsFound(null);
    setRadiusError(null);
    setLatitudeError(null);
    setResetForm(() => true);
  };

  useEffect(() => {
    if (resetGeoValues) {
      resetValues();
    }
  }, [resetGeoValues]);

  useEffect(() => {
    setFleetMessageActiveChannels([]);
  }, []);

  const toggleLocation = (channelId: string) => {
    if (fleetMessageActiveChannels.includes(channelId)) {
      setFleetMessageActiveChannels(fleetMessageActiveChannels.filter((id) => id !== channelId));
    } else {
      setFleetMessageActiveChannels([...fleetMessageActiveChannels, channelId]);
    }
  };

  const toggleAvailableRecipients = (ids: string[]) => {
    const hasSome = ids.some((feedId) => fleetMessageActiveChannels?.includes(feedId));
    if (hasSome) {
      setFleetMessageActiveChannels(fleetMessageActiveChannels?.filter((feedId) => !ids.includes(feedId)));
    } else {
      setFleetMessageActiveChannels([...new Set([...fleetMessageActiveChannels, ...ids])]);
    }
  };

  return (
    <Box sx={{ height: "390px" }}>
      <Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box sx={{ mb: 2, flexGrow: 1 }}>
            <GooglePlacesAutocomplete
              autoFocusLocation={fleetMessageInvalidLocation}
              location={fleetMessageLocation}
              resetForm={resetForm || fleetMessageLocation === ""}
              setLocationValue={(value) => {
                setFleetMessageLocation(value?.placePrediction?.text?.text || "");
                setLocalLocationAndReset(value);
              }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <FullInput
              required={true}
              label={Locator.feed.input.fleetMessage.modal.radius}
              value={fleetMessageRadius}
              error={!!radiusError}
              helperText={radiusError ?? "Max: 5,000 miles"}
              aria-label={Locator.feed.input.fleetMessage.modal.radius}
              type="number"
              callback={(e) => {
                const inputValue = (e.target as HTMLInputElement).value;
                setFleetMessageRadius(inputValue);
              }}
              placeholder="Enter radius in miles"
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box sx={{ width: "50%" }}>
            <FullInput
              required={true}
              label={Locator.feed.input.fleetMessage.modal.latitude}
              value={fleetMessageLatitude}
              error={!!latitudeError}
              helperText={latitudeError}
              aria-label={Locator.feed.input.fleetMessage.modal.latitude}
              callback={(e) => {
                const inputValue = (e.target as HTMLInputElement).value;
                setFleetMessageLatitude(inputValue);
              }}
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <FullInput
              required={true}
              label={Locator.feed.input.fleetMessage.modal.longitude}
              value={fleetMessageLongitude}
              error={!!longitudeError}
              helperText={longitudeError}
              aria-label={Locator.feed.input.fleetMessage.modal.longitude}
              callback={(e) => {
                const inputValue = (e.target as HTMLInputElement).value;
                setFleetMessageLongitude(inputValue);
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        {resultsFound?.length === 0 && (
          <Box aria-label={Locator.feed.input.fleetMessage.modal.noRecipientsFound}>
            {Locator.feed.input.fleetMessage.modal.noRecipientsFound}
          </Box>
        )}

        {fleetMessageInvalidLocation && !resultsFound && (
          <Box sx={{ background: theme.palette.error.dark, p: 1 }}>
            No location data found for this message. Please enter a location above.
          </Box>
        )}

        {resultsFound?.length > 0 && (
          <Box>
            <ListItemButton
              sx={{ display: "flex", p: 0.5, gap: 1, mb: 1, alignItems: "center" }}
              onClick={() => {
                toggleAvailableRecipients(resultsFound?.map((feed) => feed.id));
              }}
            >
              {resultsFound.map((feed) => feed.id).some((feedId) => fleetMessageActiveChannels?.includes(feedId)) ? (
                <IndeterminateCheckBoxIcon />
              ) : (
                <CheckBoxOutlineBlankIcon />
              )}
              {resultsFound.length} Recipient{pluralValue(resultsFound.length)} matching location query
            </ListItemButton>
            <Collapse
              in={resultsFound.map((feed) => feed.id).some((feedId) => fleetMessageActiveChannels?.includes(feedId))}
            >
              <Box sx={{ ml: 2, height: "165px", overflowY: "auto" }}>
                {resultsFound?.map((channel) => (
                  <ListItemButton key={channel.id} sx={{ p: 0 }} onClick={() => toggleLocation(channel.id)}>
                    <Checkbox
                      sx={{ p: 0.5 }}
                      checked={fleetMessageActiveChannels?.includes(channel.id)}
                      inputProps={{
                        "aria-labelledby": channel?.id,
                        "data-testid": Locator.feed.input.fleetMessage.modal.testId(channel?.id),
                      }}
                    />
                    <Box>{channel.title}</Box>
                  </ListItemButton>
                ))}
              </Box>
            </Collapse>
          </Box>
        )}
      </Box>
    </Box>
  );
}

import SendIcon from "@/components/Icons/Send";
import RadialProgress from "@/components/RadialProgress";
import AudioLevelIndicator from "@/components/Visualization/AudioLevelIndicator";
import Locator from "@/locator";
import ClearIcon from "@mui/icons-material/Clear";
import HourglassTopTwoToneIcon from "@mui/icons-material/HourglassTopTwoTone";
import MicIcon from "@mui/icons-material/Mic";
import { Box, Chip, useTheme } from "@mui/material";
import { useMemo } from "react";

export default function RecordingButton({
  processing,
  progress,
  color,
  width,
  disabled,
  showAudioLevels,
  audioLevel,
  time,
  uploading,
  toggleRecord,
  cancelRecord,
}: {
  processing: boolean;
  progress: number;
  color: string;
  width: number;
  disabled: boolean;
  showAudioLevels: boolean;
  audioLevel: number;
  time: string;
  uploading: boolean;
  toggleRecord: () => void;
  cancelRecord: () => void;
}) {
  const size = `${width}px`;
  const radialSize = width + 15;
  const theme = useTheme();
  const recording = progress > 0;

  const buttonIcon = useMemo(() => {
    if (processing || uploading) {
      return <HourglassTopTwoToneIcon sx={{ fontSize: 30 }} />;
    }
    if (recording) {
      return <SendIcon width={30} height={30} />;
    }
    return <MicIcon sx={{ fontSize: 30 }} />;
  }, [recording, processing, uploading]);

  const spinning = uploading && {
    animation: "spin 2s linear infinite",
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        id="recordButton"
        component="button"
        className="basic-button"
        disabled={disabled || uploading}
        onClick={() => toggleRecord()}
        aria-label={Locator.feed.input.recorder.main}
        sx={{
          zIndex: "100",
          width: size,
          height: size,
          background: "orangered",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "100%",
          ...spinning,
          "&:hover": {
            background: "red",
          },
        }}
      >
        {buttonIcon}
      </Box>
      {progress > 0 && (
        <>
          <Box
            sx={{
              position: "absolute",
              left: "0",
              top: "-70px",
              width: "100%",
              zIndex: "-1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              role="presentation"
              sx={{
                scale: showAudioLevels ? "100%" : 0,
                opacity: showAudioLevels ? 1 : 0,
                transition: "scale 0.3s ease-in-out, opacity 0.3s ease-in-out",
              }}
            >
              {showAudioLevels && (
                <Box>
                  <Chip
                    size="small"
                    sx={{ background: theme.palette.info.main }}
                    label={<AudioLevelIndicator audioLevel={audioLevel} color={theme.palette.primary.main} size={30} />}
                  />
                  <Box sx={{ fontWeight: 600, fontSize: "1rem", textAlign: "center" }}>{time}</Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{ position: "absolute", left: "-7px", top: "-7px", zIndex: "-1" }}
            className="radial-progress-indicator"
            aria-label={Locator.feed.input.recorder.progress}
          >
            <RadialProgress
              progress={progress}
              width={radialSize}
              ringColor={theme.palette.secondary.light}
              progressColor={color}
              backgroundColor={theme.palette.primary.dark}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              top: "60px",
            }}
          >
            <Box
              component="button"
              className="basic-button"
              onClick={cancelRecord}
              aria-label={Locator.feed.input.recorder.cancel}
              disabled={disabled || !recording}
              tabIndex={recording ? 0 : -1}
              sx={{
                borderRadius: "100%",
                width: "30px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ClearIcon role="img" />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

import StatusLight from "@/components/StatusLight";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { grayColor } from "@/utils";
import { transformWorkspaceMembershipAvailabilityRecord } from "@/utils/transformers";
import Person from "@mui/icons-material/Person";
import { SxProps, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import { useContext, useRef } from "react";

export default function AvatarWithStatus({
  size,
  accountId,
  sx,
}: {
  size?: number;
  accountId: string;
  sx?: SxProps;
}) {
  const { accountMap } = useContext(WorkspaceContext);
  const accountStatus = accountMap.get(accountId);
  const avatarSize = size || 32;
  const lightSize = avatarSize / 3;
  const avatarBackgroundColor = accountStatus?.avatarColor ?? grayColor;
  const initial = accountStatus?.name && accountStatus?.name?.length > 0 ? accountStatus?.name[0]?.toUpperCase() : null;
  const initialRef = useRef<HTMLDivElement>(null);
  const left = (avatarSize - initialRef?.current?.offsetWidth) / 2;
  const top = (avatarSize - initialRef?.current?.offsetHeight) / 2;

  const transformedRecord =
    accountStatus?.alias && accountStatus?.calculatedAvailability
      ? transformWorkspaceMembershipAvailabilityRecord(accountStatus)
      : null;

  if (!accountStatus) {
    return (
      <Box
        sx={{
          width: `${avatarSize}px`,
          height: `${avatarSize}px`,
          borderRadius: "100%",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: !initial ? "solid 2px #fff" : "none",
          ...sx,
        }}
      >
        <Person
          sx={{
            width: `${avatarSize / 1.5}px`,
            height: `${avatarSize / 1.5}px`,
          }}
        />
      </Box>
    );
  }

  return (
    <Tooltip
      title={transformedRecord?.toolTip}
      disableFocusListener={!transformedRecord?.toolTip}
      disableHoverListener={!transformedRecord?.toolTip}
      disableTouchListener={!transformedRecord?.toolTip}
    >
      <Box
        sx={{
          position: "relative",
          width: `${avatarSize}px`,
          height: `${avatarSize}px`,
          ...sx,
        }}
      >
        <Box
          sx={{
            width: `${avatarSize}px`,
            height: `${avatarSize}px`,
            background: avatarBackgroundColor,
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              left,
              top,
              textAlign: "center",
              fontWeight: 700,
              fontSize: `${avatarSize / 2}px`,
              lineHeight: `${avatarSize / 2}px`,
              textTransform: "uppercase",
              color: "#fff",
              height: `${avatarSize / 2 - 2}px`,
            }}
            ref={initialRef}
          >
            {initial}
          </Box>
        </Box>
        {transformedRecord?.color && (
          <StatusLight
            sx={{
              position: "absolute",
              right: "-2px",
              bottom: "-2px",
              border: "solid 2px #333",
            }}
            color={transformedRecord?.color}
            size={lightSize}
          />
        )}
      </Box>
    </Tooltip>
  );
}

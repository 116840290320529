import CloseIcon from "@mui/icons-material/Close";
import { Box, Fade, Modal, SxProps, Typography, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton/IconButton";
import React from "react";

export default function SimpleModal({
  open,
  children,
  disableClose,
  closeModal,
  title,
  sx,
}: {
  open: boolean;
  children: React.ReactNode;
  closeModal: () => void;
  footerSlot?: React.ReactNode;
  title?: string;
  sx?: SxProps;
  disableClose?: boolean;
}) {
  const theme = useTheme();

  const handleClose = () => {
    if (disableClose) {
      return;
    }
    closeModal();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(8px)",
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            width: "600px",
            pb: 2,
            background: theme.palette.secondary.dark,
            borderRadius: "1rem",
            outline: "none !important",
            ...sx,
          }}
        >
          <Box sx={{ display: "flex", width: "100%", py: 1, px: 2 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h6" component="h2" aria-label={title}>
                {title}
              </Typography>
            </Box>
            {!disableClose && (
              <Box>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="primary"
                  onClick={(e) => handleClose()}
                  disabled={false}
                >
                  <CloseIcon role="img" />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              minHeight: "150px",
              maxHeight: "calc(100vh / 1.25)",
              overflowY: "auto",
              overflowX: "hidden",
              px: 2,
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box sx={{ width: "100%", height: "100%" }}>{children}</Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

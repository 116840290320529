import { useEffect, useRef, useState } from "react";

export default function UseRequestAnimation() {
  const requestId = useRef<number>(0);
  const [status, setStatus] = useState<Date | null>(null);
  const [seconds, setSeconds] = useState<number>(0);
  const ringingInterval = () => {
    const now = new Date();
    const nowTime = now?.getTime();
    const then = status?.getTime();
    const timeInSeconds = Math.floor(Math.abs(then - nowTime) / 1000);
    setSeconds(() => timeInSeconds);
    requestId.current = requestAnimationFrame(ringingInterval);
  };

  useEffect(() => {
    requestId.current = requestAnimationFrame(ringingInterval);
    return () => {
      cancelAnimationFrame(requestId?.current);
    };
  }, [status !== null]);

  useEffect(() => {
    setStatus(new Date());
  }, []);

  return {
    seconds,
    status,
    setStatus,
  };
}
